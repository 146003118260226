import React, {useState, useEffect, useRef} from 'react';
import {Input, Button, Table, Tag, Row, Space, message, Descriptions, Typography} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import {getApiWithAuthToken} from '../../../api';
import {useLocation} from 'react-router-dom';
const {Text} = Typography;

const CollectionsDetails = () => {
  const collection_code = new URLSearchParams(useLocation().search).get('collection_code');

  const [loading, setLoading] = useState(true);
  const [collectionsDetailsList, setCollectionsDetailsList] = useState([]);
  const [collectionsSummary, setCollectionsSummary] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  function getCollectionsSummary() {
    getApiWithAuthToken(`/api/v1/admin/collectionsSummary?collection_code=${collection_code}`).then(
      res => {
        if (res.data && res.data.length > 0) {
          setCollectionsSummary(res.data[0]);
        }
      }
    );
  }

  const getCollectionsDetailsList = (
    limit = 10,
    page = 1,
    invoice_no = null,
    customer = null,
    route = null,
    collectionsman = null,
    field = null,
    order = null
  ) => {
    getApiWithAuthToken(
      `/api/v1/admin/collectionsDetails?collection_code=${collection_code}&limit=${limit}&page=${page}` +
        (invoice_no == null ? '' : '&invoice_no=' + invoice_no) +
        (customer == null ? '' : '&customer=' + customer) +
        (route == null ? '' : '&route=' + route) +
        (collectionsman == null ? '' : '&collectionsman=' + collectionsman) +
        (field == null ? '' : '&field=' + field) +
        (order == null ? '' : '&order=' + order)
    ).then(res => {
      if (!res.error && res.data.collections_details.length) {
        setTotalCount(res.data.total_count);
        setCollectionsDetailsList([]);
        res.data.collections_details.map(each => {
          setCollectionsDetailsList(collectionsDetailsList => [
            ...collectionsDetailsList,
            {
              id: each.id,
              key: each.id,
              detail_code: each.detail_code,
              payment_type_name: each.payment_type_name,
              amount: each.amount,
              balance: each.balance,
              paid: each.paid,
              invoices: each.invoices,
            },
          ]);
        });
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
          setCollectionsDetailsList([]);
        } else {
          if (res.errors) {
            message.error(res.errors);
            setCollectionsDetailsList([]);
          } else {
            message.error({content: 'Network Error!'});
            setCollectionsDetailsList([]);
          }
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCollectionsSummary();
    getCollectionsDetailsList(pageSize, currentPage);
  }, []);

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex} `}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // confirm();
    if (dataIndex === 'invoice_no') {
      getCollectionsDetailsList(pageSize, 1, selectedKeys[0]);
    }
    if (dataIndex === 'customer') {
      getCollectionsDetailsList(pageSize, 1, null, selectedKeys[0]);
    }
    if (dataIndex === 'route') {
      getCollectionsDetailsList(pageSize, 1, null, null, selectedKeys[0]);
    }
    if (dataIndex === 'collectionsman') {
      getCollectionsDetailsList(pageSize, 1, null, null, null, selectedKeys[0]);
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    getCollectionsDetailsList(pageSize, 1);
    clearFilters();
    setSearchText('');
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getCollectionsDetailsList(
      pagination.pageSize,
      pagination.current,
      filters.invoice_no,
      filters.customer,
      filters.route,
      filters.collectionsman,
      sorter.field,
      sorter.order
    );
  };

  const columns = [
    // detail_code: each.detail_code,
    // collections_type: each.collections_type,
    // product_name: each.product_name,
    // collections_total: each.collections_total,
    {
      title: 'Detail code',
      dataIndex: 'detail_code',
      key: 'detail_code',
      // ...getColumnSearchProps('detail_code'),
      sorter: (a, b) => (a.detail_code ?? '').localeCompare(b.detail_code ?? ''),
    },
    {
      title: 'Payment type',
      dataIndex: 'payment_type_name',
      key: 'payment_type_name',
      sorter: (a, b) => (a.payment_type_name ?? '').localeCompare(b.payment_type_name ?? ''),
      filters: [
        {
          text: 'Bank Transfer',
          value: 'Bank Transfer',
        },
        {
          text: 'Cash',
          value: 'Cash',
        },
        {
          text: 'POS',
          value: 'POS',
        },
      ],
      onFilter: (value, record) =>
        record.payment_type_name.toLowerCase().indexOf(value.toLowerCase()) === 0,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => (a.amount ?? '').localeCompare(b.amount ?? ''),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      sorter: (a, b) => (a.balance ?? '').localeCompare(b.balance ?? ''),
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      key: 'paid',
      sorter: (a, b) => (a.paid ?? '').localeCompare(b.paid ?? ''),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Collections Details
        </Typography.Title>
      </Row>
      <Descriptions bordered style={{backgroundColor: '#fff', marginBottom: '24px'}}>
        <Descriptions.Item label="Collection Code">
          {collectionsSummary.collection_code}
        </Descriptions.Item>
        <Descriptions.Item label="Date">{collectionsSummary.date}</Descriptions.Item>
        <Descriptions.Item label="Customer">{collectionsSummary.customer_name}</Descriptions.Item>
        <Descriptions.Item label="Route">{collectionsSummary.route_name}</Descriptions.Item>
        <Descriptions.Item label="Salesman">{collectionsSummary.salesman_name}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color={collectionsSummary.status == 'printed' ? 'green' : 'red'}>
            {collectionsSummary.status?.charAt(0).toUpperCase() +
              collectionsSummary.status?.slice(1)}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
      <Table
        scroll={{x: 0}}
        dataSource={collectionsDetailsList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: currentPage,
          size: 'default',
          pageSize: pageSize,
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
        expandable={{
          expandedRowRender: record => (
            <Table
              dataSource={record.invoices}
              pagination={false}
              rowKey="invoice"
              columns={[
                {
                  title: 'Inovice',
                  dataIndex: 'invoice',
                  key: 'invoice',
                },
                {
                  title: 'Amount',
                  dataIndex: 'amount',
                  key: 'amount',
                },
                {
                  title: 'Paid',
                  dataIndex: 'paid',
                  key: 'paid',
                },
                {
                  title: 'Balance',
                  dataIndex: 'balance',
                  key: 'balance',
                },
              ]}
            />
          ),
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>Total Paid</Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text strong>{collectionsSummary.total_amount}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </>
  );
};
export default CollectionsDetails;
