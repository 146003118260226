import React from 'react';
import SalesSummaryTable from '../../../components/Transactions/Sales/SalesSummaryTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <SalesSummaryTable />
        </ContentView>
    );
};
export default index;
