import React from 'react';
import ProductPriceTable from '../../../components/PriceMasters/ProductPrice/ProductPriceTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
  return (
    <ContentView>
      <ProductPriceTable />
    </ContentView>
  );
};
export default index;
