import React, {useState, useEffect, useRef} from 'react';
import {Input, Button, Table, Row, Space, message, Popconfirm, Typography} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import {getApiWithAuthToken, deleteApiWithAuthToken} from '../../../api';
import ProductSubcategoryForm from './ProductSubcategoryForm';

const ProductSubcategory = () => {
  const [loading, setLoading] = useState(true);
  const [productSubcategoryList, setProductSubcategoryList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getProductSubcategoryList = (
    limit = 10,
    page = 1,
    name = null,
    code = null,
    ref_code = null,
    category = null,
    field = null,
    order = null
  ) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/productSubcategories?limit=${limit}&page=${page}` +
        (name == null ? '' : '&name=' + name) +
        (code == null ? '' : '&code=' + code) +
        (ref_code == null ? '' : '&ref_code=' + ref_code) +
        (category == null ? '' : '&category=' + category) +
        (field == null ? '' : '&field=' + field) +
        (order == null ? '' : '&order=' + order)
    ).then(res => {
      if (!res.error && res.data.productSubcategories.length) {
        setTotalCount(res.data.total_count);
        setProductSubcategoryList([]);
        res.data.productSubcategories.map(each =>
          setProductSubcategoryList(productSubcategoryList => [
            ...productSubcategoryList,
            {
              id: each.id,
              code: each.code,
              name: each.name,
              ref_code: each.ref_code,
              category: each.category,
              category_id: each.category_id,
            },
          ])
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
          setProductSubcategoryList([]);
        } else {
          if (res.errors) {
            message.error(res.errors);
            setProductSubcategoryList([]);
          } else {
            message.error({content: 'Network Error!'});
            setProductSubcategoryList([]);
          }
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getProductSubcategoryList(pageSize, currentPage);
  }, []);

  // Delete function
  function handleDeleteClick(id) {
    message.loading({content: 'Processing...', key: id});
    deleteApiWithAuthToken(`/api/v1/admin/productSubcategories/${id}`).then(res => {
      if (res.error) {
        if (res.errors.errno === 1451) {
          message.error({
            content: 'Could not delete!, The productSubcategory is being referred in another place',
            key: id,
          });
        } else {
          message.error({
            content: res.errors.sqlMessage,
            key: id,
          });
        }
      } else {
        message.success({
          content: 'Deleted!',
          key: id,
        });
        getProductSubcategoryList();
      }
    });
  }

  // Edit function

  const [selectedProductSubcategory, setSelectedProductSubcategory] = useState({});
  const handleEditClick = record => {
    setSelectedProductSubcategory(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedProductSubcategory({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex} `}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // confirm();
    setCurrentPage(1);
    if (dataIndex === 'name') {
      getProductSubcategoryList(pageSize, 1, selectedKeys[0]);
    }
    if (dataIndex === 'code') {
      getProductSubcategoryList(pageSize, 1, null, selectedKeys[0]);
    }
    if (dataIndex === 'ref_code') {
      getProductSubcategoryList(pageSize, 1, null, null, selectedKeys[0]);
    }
    if (dataIndex === 'category') {
      getProductSubcategoryList(pageSize, 1, null, null, null, selectedKeys[0]);
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    setCurrentPage(1);
    getProductSubcategoryList(pageSize, 1);
    clearFilters();
    setSearchText('');
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getProductSubcategoryList(
      pagination.pageSize,
      pagination.current,
      filters.name,
      filters.code,
      filters.ref_code,
      filters.category,
      sorter.field,
      sorter.order
    );
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...getColumnSearchProps('code'),
      sorter: true,
    },
    {
      title: 'Ref Code',
      dataIndex: 'ref_code',
      key: 'ref_code',
      ...getColumnSearchProps('ref_code'),
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      ...getColumnSearchProps('category'),
      sorter: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>{' '}
          <span> </span>
          <Popconfirm
            title="Are you sure to delete this product subcategory?"
            onConfirm={() => handleDeleteClick(record.id)}
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Product Sub-Category
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table
        scroll={{x: 0}}
        dataSource={productSubcategoryList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: currentPage,
          size: 'default',
          pageSize: pageSize,
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
      />
      <ProductSubcategoryForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getProductSubcategoryList={getProductSubcategoryList}
        productSubcategory={selectedProductSubcategory}
      />
    </>
  );
};

export default ProductSubcategory;
