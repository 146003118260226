import React, {useState, useEffect, useRef} from 'react';
import {Input, Button, Table, Row, Space, message, Popconfirm, Typography} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import {getApiWithAuthToken, deleteApiWithAuthToken} from '../../../api';
import AreaForm from './AreaForm';

const Area = () => {
  const [areaList, setAreaList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAreaList = () => {
    getApiWithAuthToken('/api/v1/admin/areas').then(res => {
      if (!res.error && res.data.length) {
        setAreaList(
          res.data.map(each => ({
            id: each.id,
            code: each.code,
            ref_code: each.ref_code,
            key: each.id,
            name: each.name,
            prefix: each.prefix,
            trans_id: each.trans_id,
            manager: each.manager,
            region: each.region,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({
            content: 'Network Error!',
          });
        }
        setAreaList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getAreaList();
  }, []);

  // Delete function
  function handleDeleteClick(id) {
    message.loading({
      content: 'Processing...',
      key: id,
    });

    deleteApiWithAuthToken(`/api/v1/admin/areas/${id}`).then(res => {
      if (res.error) {
        setTimeout(() => {
          if (res.errors.errno === 1451) {
            message.error({
              content: 'Could not delete!, The area is being referred in another place',
              key: id,
            });
          } else {
            message.error({
              content: res.errors.sqlMessage,
              key: id,
            });
          }
        }, 1000);
      } else {
        message.success({
          content: 'Deleted!',
          key: id,
        });
        getAreaList();
      }
    });
  }

  // Edit function

  const [selectedArea, setSelectedArea] = useState({});
  const handleEditClick = record => {
    setSelectedArea(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedArea({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small">
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...getColumnSearchProps('code'),
      sorter: (a, b) => (a.code ?? '').localeCompare(b.ref_code ?? ''),
    },
    {
      title: 'Ref Code',
      dataIndex: 'ref_code',
      key: 'ref_code',
      ...getColumnSearchProps('ref_code'),
      sorter: (a, b) => (a.ref_code ?? '').localeCompare(b.ref_code ?? ''),
    },
    {
      title: 'Prefix',
      dataIndex: 'prefix',
      key: 'prefix',
      ...getColumnSearchProps('prefix'),
      sorter: (a, b) => (a.prefix ?? '').localeCompare(b.prefix ?? ''),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      key: 'manager',
      className: 'table-col-capitalize',
      ...getColumnSearchProps('manager'),
      sorter: (a, b) => (a.manager ?? '').localeCompare(b.manager ?? ''),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      className: 'table-col-capitalize',
      ...getColumnSearchProps('region'),
      sorter: (a, b) => (a.region ?? '').localeCompare(b.region ?? ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
          <span> </span>
          <Popconfirm
            title="Are you sure to delete this Area?"
            onConfirm={() => handleDeleteClick(record.id)}
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row
        justify="space-between"
        style={{
          margin: '24px 0',
        }}
      >
        <Typography.Title
          level={3}
          style={{
            marginBottom: '0',
          }}
        >
          Area
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table
        scroll={{
          x: 0,
        }}
        dataSource={areaList}
        columns={columns}
        loading={loading}
      />
      <AreaForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getAreaList={getAreaList}
        area={selectedArea}
      />
    </>
  );
};
export default Area;
