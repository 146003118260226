import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Drawer, message, Col, Row, DatePicker } from 'antd';
import { postApiWithAuthToken, getApiWithAuthToken, getApiWithPublicAuthToken, updateApiWithAuthToken } from '../../../api';

const ProductSubcategoryForm = ({ handleDrawerClose, isDrawerVisible, getProductSubcategoryList, productSubcategory }) => {
    const [form] = Form.useForm();

    const [productCategoryList, setProductCategoryList] = useState([]);

    function getProductCategoryList() {
        getApiWithAuthToken('/api/v1/admin/productCategories').then((res) => {
            if (res.data && res.data.length > 0) {
                setProductCategoryList(res.data);
            }
        });
    }

    useEffect(() => {
        getProductCategoryList();
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [productSubcategory]);

    // Form Submit 

    const handleSubmit = () => {
        form.submit();
    };

    const onFinish = (values) => {
        message.loading({ content: 'Processing...', key: 'form' });

        if (productSubcategory.id) {
            updateApiWithAuthToken(`/api/v1/admin/productSubcategories/${productSubcategory.id}`, values).then((res) => {
                if (!res.error) {
                    setTimeout(() => {
                        message.success({ content: res.msg, key: 'form' });
                    }, 500);
                    handleDrawerClose();
                    getProductSubcategoryList();
                } else {
                    setTimeout(() => {
                        if (res.errors.length) {
                            res.errors.map((each) => {
                                message.error({ content: each.msg, key: 'form' });
                                return null;
                            });
                        } else {
                            message.error({ content: res.errors, key: 'form' });
                        }
                    }, 500);
                }
            });
        } else {
            postApiWithAuthToken('/api/v1/admin/productSubcategories', values).then((res) => {
                if (!res.error) {
                    setTimeout(() => {
                        message.success({ content: res.msg, key: 'form' });
                    }, 500);
                    handleDrawerClose();
                    getProductSubcategoryList();
                } else {
                    setTimeout(() => {
                        if (res.errors.length) {
                            res.errors.map((each) => {
                                message.error({ content: each.msg, key: 'form' });
                                return null;
                            });
                        } else {
                            message.error({ content: res.errors, key: 'form' });
                        }
                    }, 500);
                }
            });
        }
    };

    return (
        <Drawer
            title={productSubcategory.id ? "Edit product subcategory" : "Add product subcategory"}
            width={480}
            onClose={handleDrawerClose}
            visible={isDrawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div style={{ textAlign: 'right', }}>
                    <Button onClick={handleDrawerClose} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} type="primary">
                        Submit
                    </Button>
                </div>
            }
        >
            <Form layout="vertical"
                hideRequiredMark
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish} >
                <Form.Item
                    name="name"
                    label="Name"
                    initialValue={productSubcategory.name}
                    rules={[{ required: true, message: 'This field is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="ref_code"
                    label="Ref Code"
                    initialValue={productSubcategory.ref_code}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="category"
                    label="Category"
                    initialValue={productCategoryList.find(category => category.name === productSubcategory.category) ? productCategoryList.find(category => category.name === productSubcategory.category).id : null}
                >
                    <Select
                        showSearch
                        placeholder="Select a category"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {productCategoryList.length &&
                            productCategoryList.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
            </Form>
        </Drawer >
    );
};
export default ProductSubcategoryForm;
