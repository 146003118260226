import React, {useState, useEffect} from 'react';
import {Form, Input, Select, Button, Drawer, message, InputNumber, Row, DatePicker} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';

const PriceKeyForm = ({handleDrawerClose, isDrawerVisible, getPriceKeyList, priceKey}) => {
  const [form] = Form.useForm();

  const [currencyList, setCurrencyList] = useState([]);
  const [productMultipackList, setProductMultipackList] = useState([]);

  function getCurrencyList() {
    getApiWithPublicAuthToken('/api/v1/public/currencyList').then(res => {
      if (res.data && res.data.length > 0) {
        setCurrencyList(res.data);
      }
    });
  }
  function getProductMultipackList() {
    getApiWithAuthToken('/api/v1/admin/productMultipacks').then(res => {
      if (res.data && res.data.length > 0) {
        setProductMultipackList(res.data);
      }
    });
  }

  useEffect(() => {
    getCurrencyList();
    getProductMultipackList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [priceKey]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});

    if (priceKey.id) {
      updateApiWithAuthToken(`/api/v1/admin/priceKeys/${priceKey.id}`, values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getPriceKeyList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    } else {
      postApiWithAuthToken('/api/v1/admin/priceKeys', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getPriceKeyList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={priceKey.id ? 'Edit price key ' : 'Add price key '}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        initialValues={{remember: true}}
        onFinish={onFinish}
      >
        <Form.Item
          name="description"
          label="Description"
          initialValue={priceKey.description}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="multipack"
          label="Multipack"
          initialValue={
            productMultipackList.find(
              productMultipack => productMultipack.name === priceKey.multipack
            )
              ? productMultipackList.find(
                  productMultipack => productMultipack.name === priceKey.multipack
                ).id
              : null
          }
        >
          <Select showSearch placeholder="Select a multipack" optionFilterProp="children">
            {productMultipackList.length &&
              productMultipackList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="quantity" label="Quantity" initialValue={priceKey.quantity}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="cost_price" label="Cost price" initialValue={priceKey.cost_price}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="min_sales" label="Min sales" initialValue={priceKey.min_sales}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="min_gr" label="Min GR" initialValue={priceKey.min_gr}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="min_damage" label="Min damage" initialValue={priceKey.min_damage}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="min_expiry" label="Min expiry" initialValue={priceKey.min_expiry}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="min_foc" label="Min FOC" initialValue={priceKey.min_foc}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="max_discount" label="Max discount" initialValue={priceKey.max_discount}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="sales" label="Sales" initialValue={priceKey.sales}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="gr" label="GR" initialValue={priceKey.gr}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="expiry" label="Expiry" initialValue={priceKey.expiry}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="damage" label="Damage" initialValue={priceKey.damage}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="foc" label="FOC" initialValue={priceKey.foc}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="currency"
          label="Currency"
          initialValue={
            currencyList.find(currency => currency.name === priceKey.currency)
              ? currencyList.find(currency => currency.name === priceKey.currency).id
              : null
          }
        >
          <Select showSearch placeholder="Select a currency" optionFilterProp="children">
            {currencyList.length &&
              currencyList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default PriceKeyForm;
