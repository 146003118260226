import React from 'react';
import RouteJourneyPlanTable from '../../../components/RouteMasters/RouteJourneyPlan/RouteJourneyPlanTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <RouteJourneyPlanTable />
        </ContentView>
    );
};
export default index;
