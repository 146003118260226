import React from 'react';
import AreaTable from '../../../components/LocaleMasters/Area/AreaTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <AreaTable />
        </ContentView>
    );
};
export default index;
