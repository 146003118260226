import React from 'react';
import PaymentType from './PaymentType';
import ContentView from '../../Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <PaymentType />
        </ContentView>
    );
};
export default index;
