import React, {useState, useEffect} from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Drawer,
  message,
  Upload,
  Checkbox,
  Divider,
  Typography,
  Row,
  Col,
  Space,
} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateWithFileApiWithAuthToken,
  getApiWithAuthToken,
} from '../../../api';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import toTitleCase from 'helpers/toTitleCase';

const ManagerForm = ({handleDrawerClose, isDrawerVisible, getManagerList, manager}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [languageList, setLanguageList] = useState([]);
  const [permissionControllerList, setPermissionControllerList] = useState([]);
  const [enableChangePassword, setEnableChangePassword] = useState(true);
  const [profileImageSrc, setProfileImageSrc] = useState();

  function getLanguageList() {
    getApiWithPublicAuthToken('/api/v1/public/languageList').then(res => {
      if (res.data && res.data.length > 0) {
        res.data.map(each => (each.name = toTitleCase(each.name)));
        setLanguageList(res.data);
      }
    });
  }

  function getPermissionControllerList() {
    getApiWithAuthToken('/api/v1/admin/permissionControllers').then(res => {
      if (res.data && res.data.length > 0) {
        setPermissionControllerList(res.data);
      }
    });
  }

  useEffect(() => {
    getLanguageList();
    getPermissionControllerList();
  }, []);

  useEffect(() => {
    form.resetFields();
    setProfileImageSrc();
    if (manager.id) {
      setEnableChangePassword(false);
    } else {
      setEnableChangePassword(true);
    }
    if (manager.profile_image) setProfileImageSrc(manager.profile_image);
    else setProfileImageSrc();
  }, [manager]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});
    if (manager.id) {
      let formData = new FormData();

      for (const property in values) {
        if (property !== 'profile_image' && !property.includes('permission'))
          formData.append(property, values[property]);
      }

      if (values?.profile_image?.fileList[0]?.originFileObj) {
        formData.append('profile_image', values.profile_image?.fileList[0].originFileObj);
      }

      const permissions = [];

      for (const property in values) {
        if (property.includes('permission')) {
          const p = property.split('_');
          const i = permissions.findIndex(e => e.controller_id === p[1]);
          if (i < 0) {
            permissions.push({
              controller_id: p[1],
              [p[2]]: values[`permission_${p[1]}_${p[2]}`] ? 1 : 0,
            });
          } else {
            permissions[i][p[2]] = values[`permission_${p[1]}_${p[2]}`] ? 1 : 0;
          }
        }
      }

      formData.append('permissions', JSON.stringify(permissions));

      updateWithFileApiWithAuthToken(`/api/v1/admin/managers/${manager.id}`, formData).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getManagerList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({
                  content: each.msg,
                  key: 'form',
                });
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    } else {
      let formData = new FormData();
      formData.append('profile_image', values.profile_image?.fileList[0].originFileObj);

      for (const property in values) {
        formData.append(property, values[property]);
      }

      const permissions = [];

      for (const property in values) {
        if (property.includes('permission')) {
          const p = property.split('_');
          const i = permissions.findIndex(e => e.controller_id === p[1]);
          if (i < 0) {
            permissions.push({
              controller_id: p[1],
              [p[2]]: values[`permission_${p[1]}_${p[2]}`] ? 1 : 0,
            });
          } else {
            permissions[i][p[2]] = values[`permission_${p[1]}_${p[2]}`] ? 1 : 0;
          }
        }
      }

      formData.append('permissions', JSON.stringify(permissions));

      postApiWithAuthToken('/api/v1/admin/managers', formData).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getManagerList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({
                  content: each.msg,
                  key: 'form',
                });
                return null;
              });
            } else {
              message.error({
                content: res.errors,
                key: 'form',
              });
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={manager.id ? 'Edit manager' : 'Add manager'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form layout="vertical" hideRequiredMark form={form} onFinish={onFinish}>
        <Form.Item
          label="Profile Image"
          name="profile_image"
          // rules={[{ required: true, message: 'This field is required' }]}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            maxCount={1}
            beforeUpload={file => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                var src = reader.result;
                setProfileImageSrc(src);
              };
              return false;
            }}
          >
            {profileImageSrc ? (
              <img src={profileImageSrc} alt="avatar" style={{width: '100%'}} />
            ) : (
              <div>
                {profileImageSrc ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{marginTop: 8}}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          initialValue={manager.email}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          initialValue={manager.name}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        {manager.id && (
          <Form.Item>
            <Checkbox
              checked={enableChangePassword}
              onChange={e => setEnableChangePassword(e.target.checked)}
            >
              Change password
            </Checkbox>
          </Form.Item>
        )}
        {/* {enableChangeImage &&
                    <Form.Item
                        label="Profile Image"
                        name="profile_image"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <Upload
                            beforeUpload={() => false}
                            listType="picture"
                            className="upload-list-inline"
                            maxCount={1}
                            showUploadList={{
                                showRemoveIcon: false
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                } */}
        {enableChangePassword && (
          <>
            <Form.Item
              name="password"
              label="Password"
              initialValue={manager.password}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                {
                  min: 6,
                  message: 'Password must be minimum 6 characters.',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              dependencies={['password']}
              initialValue={manager.confirm_password}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('The two passwords that you entered do not match!')
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="lang"
          label="Language"
          initialValue={manager ? manager.lang_id : null}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select
            showSearch
            placeholder="Select a language"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {languageList.length &&
              languageList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="job_title"
          label="Job Title"
          initialValue={manager.job_title}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="collector" valuePropName="checked" initialValue={manager.collector}>
          <Checkbox>Collector</Checkbox>
        </Form.Item>
        <Divider />
        <Row gutter={16} align={'middle'} style={{marginBottom: '12px'}}>
          <Typography.Title level={4} style={{marginBottom: '0'}}>
            Permissions
          </Typography.Title>
          <Form.Item
            style={{marginLeft: '12px', marginBottom: '0'}}
            valuePropName="checked"
            onChange={e => {
              const checked = e.target.checked;
              permissionControllerList.forEach(controller => {
                form.setFieldsValue({
                  [`permission_${controller.id}_create`]: checked,
                  [`permission_${controller.id}_read`]: checked,
                  [`permission_${controller.id}_update`]: checked,
                  [`permission_${controller.id}_delete`]: checked,
                });
              });
            }}
          >
            <Checkbox></Checkbox>
          </Form.Item>
        </Row>
        {permissionControllerList.map(controller => {
          const permission = manager.permissions?.find(p => p.controller_id === controller.id) ?? {
            create_access: false,
            read_access: false,
            update_access: false,
            delete_access: false,
          };
          return (
            <>
              <Row gutter={16} align={'middle'}>
                <Typography.Title level={5} style={{marginBottom: '0'}}>
                  {controller.name}
                </Typography.Title>
                <Form.Item
                  style={{marginLeft: '12px', marginBottom: '0'}}
                  valuePropName="checked"
                  initialValue={
                    permission.create_access &&
                    permission.read_access &&
                    permission.update_access &&
                    permission.delete_access
                  }
                  onChange={e => {
                    const checked = e.target.checked;
                    form.setFieldsValue({
                      [`permission_${controller.id}_create`]: checked,
                      [`permission_${controller.id}_read`]: checked,
                      [`permission_${controller.id}_update`]: checked,
                      [`permission_${controller.id}_delete`]: checked,
                    });
                  }}
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Row>
              <Row gutter={16} justify={'space-between'} align={'middle'}>
                <Col>
                  <Form.Item
                    name={`permission_${controller.id}_create`}
                    valuePropName="checked"
                    initialValue={permission.create_access}
                  >
                    <Checkbox>Create</Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={`permission_${controller.id}_read`}
                    valuePropName="checked"
                    initialValue={permission.read_access}
                  >
                    <Checkbox>Read</Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={`permission_${controller.id}_update`}
                    valuePropName="checked"
                    initialValue={permission.update_access}
                  >
                    <Checkbox>Update</Checkbox>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={`permission_${controller.id}_delete`}
                    valuePropName="checked"
                    initialValue={permission.delete_access}
                  >
                    <Checkbox>Delete</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </>
          );
        })}
      </Form>
    </Drawer>
  );
};
export default ManagerForm;
