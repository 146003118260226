import React, {useState, useEffect} from 'react';
import {Button, Table, Row, message, Popconfirm, Typography} from 'antd';
import {getApiWithAuthToken} from '../../../api';
import RoutePriceForm from './RoutePriceForm';
import {useColumnSearchProps, handleDeleteClick, handleTableChange} from '../../../helpers/table';

const RoutePrice = () => {
  // Table states
  const [loading, setLoading] = useState(true);
  const [routePriceList, setRoutePriceList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: 10,
    page: 1,
    code: null,
    ref_code: null,
    route: null,
    price_key: null,
    field: null,
    order: null,
  });

  const getRoutePriceList = (qp = queryParams) => {
    getApiWithAuthToken(
      `/api/v1/admin/routePrices?limit=${qp.limit}&page=${qp.page}` +
        (qp.code ? `&code=${qp.code}` : '') +
        (qp.ref_code ? `&ref_code=${qp.ref_code}` : '') +
        (qp.route ? `&route=${qp.route}` : '') +
        (qp.price_key ? `&price_key=${qp.price_key}` : '') +
        (qp.field ? `&field=${qp.field}` : '') +
        (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      if (!res.error && res.data?.route_prices.length) {
        setTotalCount(res.data.total_count);
        setRoutePriceList(
          res.data.route_prices.map(each => ({
            id: each.id,
            code: each.code,
            ref_code: each.ref_code,
            price_key: each.price_key,
            from_date: each.from_date,
            to_date: each.to_date,
            route: each.route,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({content: 'Network Error!'});
        }
        setRoutePriceList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getRoutePriceList(queryParams);
  }, []);

  // Edit function

  const [selectedRoutePrice, setSelectedRoutePrice] = useState({});
  const handleEditClick = record => {
    setSelectedRoutePrice(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedRoutePrice({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...useColumnSearchProps('code', queryParams, setQueryParams, getRoutePriceList),
      sorter: true,
    },
    {
      title: 'Ref code',
      dataIndex: 'ref_code',
      key: 'ref_code',
      ...useColumnSearchProps('ref_code', queryParams, setQueryParams, getRoutePriceList),
      sorter: true,
    },
    {
      title: 'Route',
      dataIndex: 'route',
      key: 'route',
      ...useColumnSearchProps('route', queryParams, setQueryParams, getRoutePriceList),
      sorter: false,
    },
    {
      title: 'Price Key',
      dataIndex: 'price_key',
      key: 'price_key',
      ...useColumnSearchProps('price_key', queryParams, setQueryParams, getRoutePriceList),
      sorter: false,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
          <span></span>
          <Popconfirm
            title="Are you sure to delete this route price?"
            onConfirm={() =>
              handleDeleteClick(record.id, '/api/v1/admin/routePrices/', () =>
                getRoutePriceList(queryParams)
              )
            }
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Route Price
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table
        scroll={{x: 0}}
        dataSource={routePriceList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange(queryParams, setQueryParams, getRoutePriceList)}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: queryParams.page,
          pageSize: queryParams.limit,
          size: 'default',
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
      />
      <RoutePriceForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getRoutePriceList={getRoutePriceList}
        routePrice={selectedRoutePrice}
      />
    </>
  );
};

export default RoutePrice;
