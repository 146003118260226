import React from 'react';
import Language from './Language';
import ContentView from '../../Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <Language />
        </ContentView>
    )
}
export default index;
