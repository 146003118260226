import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Drawer, message, Col, Row, DatePicker } from 'antd';
import { postApiWithAuthToken, getApiWithAuthToken, getApiWithPublicAuthToken, updateApiWithAuthToken } from '../../../api';
import moment from 'moment';

const RoutePriceForm = ({ handleDrawerClose, isDrawerVisible, getRoutePriceList, routePrice }) => {
    const [form] = Form.useForm();

    const [routeList, setRouteList] = useState([]);
    const [priceKeyList, setPriceKeyList] = useState([]);

    function getRouteList() {
        getApiWithPublicAuthToken('/api/v1/admin/routes').then((res) => {
            if (res.data && res.data.length > 0) {
                setRouteList(res.data);
            }
        });
    }
    function getPriceKeyList() {
        getApiWithAuthToken('/api/v1/admin/priceKeys').then((res) => {
            if (res.data && res.data.length > 0) {
                setPriceKeyList(res.data);
            }
        });
    }

    useEffect(() => {
        getRouteList();
        getPriceKeyList();
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [routePrice]);

    // Form Submit 

    const handleSubmit = () => {
        form.submit();
    };

    const onFinish = (values) => {
        message.loading({ content: 'Processing...', key: 'form' });

        if (routePrice.id) {
            updateApiWithAuthToken(`/api/v1/admin/routePrices/${routePrice.id}`, values).then((res) => {
                if (!res.error) {
                    setTimeout(() => {
                        message.success({ content: res.msg, key: 'form' });
                    }, 500);
                    handleDrawerClose();
                    getRoutePriceList();
                } else {
                    setTimeout(() => {
                        if (res.errors.length) {
                            res.errors.map((each) => {
                                message.error({ content: each.msg, key: 'form' });
                                return null;
                            });
                        } else {
                            message.error({ content: res.errors, key: 'form' });
                        }
                    }, 500);
                }
            });
        } else {
            postApiWithAuthToken('/api/v1/admin/routePrices', values).then((res) => {
                if (!res.error) {
                    setTimeout(() => {
                        message.success({ content: res.msg, key: 'form' });
                    }, 500);
                    handleDrawerClose();
                    getRoutePriceList();
                } else {
                    setTimeout(() => {
                        if (res.errors.length) {
                            res.errors.map((each) => {
                                message.error({ content: each.msg, key: 'form' });
                                return null;
                            });
                        } else {
                            message.error({ content: res.errors, key: 'form' });
                        }
                    }, 500);
                }
            });
        }
    };

    return (
        <Drawer
            title={routePrice.id ? "Edit route price " : "Add route price "}
            width={480}
            onClose={handleDrawerClose}
            visible={isDrawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div style={{ textAlign: 'right', }}>
                    <Button onClick={handleDrawerClose} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} type="primary">
                        Submit
                    </Button>
                </div>
            }
        >
            <Form layout="vertical"
                hideRequiredMark
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish} >
                <Form.Item
                    name="ref_code"
                    label="Ref Code"
                    initialValue={routePrice.ref_code}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="route"
                    label="Route"
                    initialValue={routeList.find(route => route.name === routePrice.route) ? routeList.find(route => route.name === routePrice.route).id : null}
                    rules={[{ required: true, message: 'This field is required' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a route"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {routeList.length &&
                            routeList.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="price_key"
                    label="Price key"
                    initialValue={priceKeyList.find(priceKey => priceKey.description === routePrice.price_key) ? priceKeyList.find(priceKey => priceKey.description === routePrice.price_key).id : null}
                    rules={[{ required: true, message: 'This field is required' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a price key"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {priceKeyList.length &&
                            priceKeyList.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.description}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="from_date"
                    label="From date"
                    initialValue={moment(routePrice.from_date).isValid() ? moment.utc(routePrice.from_date).local() : null}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    name="to_date"
                    label="To date"
                    initialValue={moment(routePrice.to_date).isValid() ? moment.utc(routePrice.to_date).local() : null}
                >
                    <DatePicker />
                </Form.Item>
            </Form>
        </Drawer >
    );
};
export default RoutePriceForm;
