import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import { Input, Button, Table, Row, Space, message, Popconfirm, Typography, Tag, Form, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getApiWithAuthToken, deleteApiWithAuthToken, updateApiWithAuthToken } from '../../../api';
import RouteJourneyPlanForm from './RouteJourneyPlanForm';
import RouteJourneyPlanImport from './RouteJourneyPlanImport';

const RouteJourneyPlan = () => {

    const EditableContext = createContext(null);

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        ...restProps
    }) => {


        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                title.toLowerCase() === 'route' ?
                    <Form.Item
                        style={{ margin: 0, }}
                        width={400}
                        name={dataIndex}
                    // initialValue={routeList.find(route => route.name === routeJourneyPlan.route) ? routeList.find(route => route.name === routeJourneyPlan.route).id : null}
                    >
                        <Select
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                            showSearch
                            placeholder="Select a route"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {routeList.length &&
                                routeList.map((item) => (
                                    <Select.Option
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item> :
                    <Form.Item
                        style={{ margin: 0, }}
                        width={400}
                        name={dataIndex}
                    >
                        <Select
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                            mode="multiple"
                            showSearch
                            placeholder="Select a customer"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {customerList.length &&
                                customerList.map((item) => (
                                    <Select.Option
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
            ) : (
                title.toLowerCase() === 'route' ?
                    <div
                        className="editable-cell-value-wrap"
                        style={{ paddingRight: 24 }}
                        onClick={toggleEdit}
                    >
                        {routeList.find(route => route.id === children[1])?.name}
                    </div>
                    :
                    <div
                        className="editable-cell-value-wrap"
                        style={{ paddingRight: 24 }}
                        onClick={toggleEdit}
                    >
                        {customerList.map(customer => {
                            if (children[1]?.length)
                                if (children[1]?.includes(customer.id))
                                    return <div>{customer.name}</div>;
                        })}
                    </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const [routeJourneyPlanList, setRouteJourneyPlanList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [routeList, setRouteList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalCount, setTotalCount] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    function getRouteList() {
        getApiWithAuthToken('/api/v1/admin/routes').then((res) => {
            if (res.data && res.data.length > 0) {
                setRouteList(res.data);
            }
        });
    }
    function getCustomerList() {
        getApiWithAuthToken('/api/v1/admin/customers').then((res) => {
            if (res.data && res.data.length > 0) {
                setCustomerList(res.data);
            }
        });
    }

    const getRouteJourneyPlanList = (
        limit = 10,
        page = 1,
        route = null,
        customer = null,
        field = null,
        order = null
    ) => {
        getApiWithAuthToken(`/api/v1/admin/routeJourneyPlans?limit=${limit}&page=${page}` +
            (route == null ? '' : '&route=' + route) +
            (customer == null ? '' : '&customer=' + customer) +
            (field == null ? '' : '&field=' + field) +
            (order == null ? '' : '&order=' + order)
        ).then((res) => {
            if (!res.error && res.data.route_journey_plans.length) {
                setTotalCount(res.data.total_count);
                setRouteJourneyPlanList([]);
                res.data.route_journey_plans.map((each) =>
                    setRouteJourneyPlanList((routeJourneyPlanList) => [
                        ...routeJourneyPlanList,
                        {
                            id: each.route_id,
                            key: each.route_id,
                            route: each.route,
                            route_id: each.route_id,
                            day_1: each.day_1,
                            day_2: each.day_2,
                            day_3: each.day_3,
                            day_4: each.day_4,
                            day_5: each.day_5,
                            day_6: each.day_6,
                            day_7: each.day_7,
                        },
                    ])
                );
            } else {
                if (res.errors) {
                    Object.values(res.errors).map((each) =>
                        message.error(each.msg)
                    );
                    setRouteJourneyPlanList([]);
                } else {
                    if (res.errors) {
                        message.error(res.errors);
                        setRouteJourneyPlanList([]);
                    } else {
                        message.error({ content: 'Network Error!' });
                        setRouteJourneyPlanList([]);
                    }
                }
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        getRouteJourneyPlanList(pageSize, currentPage);
        getRouteList();
        getCustomerList();
    }, []);

    // Delete function 
    function handleDeleteClick(id) {
        message.loading({ content: 'Processing...', key: id });
        deleteApiWithAuthToken(`/api/v1/admin/routeJourneyPlans/${id}`).then((res) => {
            if (res.error) {
                setTimeout(() => {
                    if (res.errors.errno === 1451) {
                        message.error({
                            content:
                                'Could not delete!, The route is being referred in another place',
                            key: id
                        });
                    } else {
                        message.error({
                            content: res.errors.sqlMessage,
                            key: id
                        });
                    }
                }, 1000);
            } else {
                setTimeout(() => {
                    message.success({
                        content: 'Deleted!',
                        key: id
                    });
                }, 1000);
                getRouteJourneyPlanList();
            }
        });
    }

    // Edit function 

    const [selectedRouteJourneyPlan, setSelectedRouteJourneyPlan] = useState({});
    const handleEditClick = (record) => {
        setSelectedRouteJourneyPlan(record);
        setIsDrawerVisible(true);
    };

    //Drawer Open function
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const showDrawer = () => {
        setSelectedRouteJourneyPlan({});
        setIsDrawerVisible(true);
    };
    const handleDrawerClose = () => {
        setIsDrawerVisible(false);
    };

    const [isImportDrawerVisible, setIsImportDrawerVisible] = useState(false);
    const showImportDrawer = () => {
        setIsImportDrawerVisible(true);
    };
    const handleImportDrawerClose = () => {
        setIsImportDrawerVisible(false);
    };

    // Table functions 

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    var searchInput = useRef(null);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button> */}
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        // confirm();
        if (dataIndex === 'route') {
            getRouteJourneyPlanList(pageSize, 1, selectedKeys[0]);
        } else {
            getRouteJourneyPlanList(pageSize, 1, null, selectedKeys[0]);
        }
        setSearchText(selectedKeys[0]);
        // setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        getRouteJourneyPlanList(pageSize, 1);
        clearFilters();
        setSearchText('');
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize);
        setCurrentPage(pagination.current);
        getRouteJourneyPlanList(
            pagination.pageSize,
            pagination.current,
            filters.route,
            filters.customer,
            sorter.field,
            sorter.order
        );
    };

    const columns = [
        {
            title: 'Route',
            dataIndex: 'route',
            key: 'route',
            ...getColumnSearchProps('route'),
            // editable: true,
            sorter: true,
            width: 400,
            // fixed: 'left',
        },
        // {
        //     title: 'Customer',
        //     dataIndex: 'customer',
        //     key: 'customer',
        //     ...getColumnSearchProps('customer'),
        //     sorter: true,
        // },
        {
            title: 'Day 1',
            dataIndex: 'day_1',
            key: 'day_1',
            ...getColumnSearchProps('day_1'),
            sorter: true,
            editable: true,
            width: 400,
        },
        {
            title: 'Day 2',
            dataIndex: 'day_2',
            key: 'day_2',
            ...getColumnSearchProps('day_2'),
            sorter: true,
            editable: true,
            width: 400,
        },
        {
            title: 'Day 3',
            dataIndex: 'day_3',
            key: 'day_3',
            ...getColumnSearchProps('day_3'),
            sorter: true,
            editable: true,
            width: 400,
        },
        {
            title: 'Day 4',
            dataIndex: 'day_4',
            key: 'day_4',
            ...getColumnSearchProps('day_4'),
            sorter: true,
            editable: true,
            width: 400,
        },
        {
            title: 'Day 5',
            dataIndex: 'day_5',
            key: 'day_5',
            ...getColumnSearchProps('day_5'),
            sorter: true,
            editable: true,
            width: 400,
        },
        {
            title: 'Day 6',
            dataIndex: 'day_6',
            key: 'day_6',
            ...getColumnSearchProps('day_6'),
            sorter: true,
            editable: true,
            width: 400,
        },
        {
            title: 'Day 7',
            dataIndex: 'day_7',
            key: 'day_7',
            ...getColumnSearchProps('day_7'),
            sorter: true,
            editable: true,
            width: 400,
        },

        // {
        //     title: 'Days',
        //     key: 'days',
        //     render: (_, record) => (
        //         <>
        //             {record.day_1 === 1 && <Tag>Monday</Tag>}
        //             {record.day_2 === 1 && <Tag>Tuesday</Tag>}
        //             {record.day_3 === 1 && <Tag>Wednesday</Tag>}
        //             {record.day_4 === 1 && <Tag>Thursday</Tag>}
        //             {record.day_5 === 1 && <Tag>Friday</Tag>}
        //             {record.day_6 === 1 && <Tag>Saturday</Tag>}
        //             {record.day_7 === 1 && <Tag>Sunday</Tag>}
        //         </>
        //     ),
        // },
        {
            title: 'Actions',
            key: 'actions',
            align: 'right',
            // fixed: 'right',
            width: 100,
            render: (_, record) => (
                <>
                    {/* <Button size='small' onClick={() => handleEditClick(record)} >
                        Edit
                    </Button> <span> </span> */}
                    <Popconfirm
                        title='Are you sure to delete this route?'
                        onConfirm={() => handleDeleteClick(record.id)}
                    >
                        <Button danger size='small'>
                            Delete
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const cols = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        };
    });


    const handleSave = (row) => {
        const newData = [...routeJourneyPlanList];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];

        newData.splice(index, 1, { ...item, ...row });
        setRouteJourneyPlanList(newData);

        delete row.id;
        delete row.key;
        delete row.route;

        updateApiWithAuthToken(`/api/v1/admin/routeJourneyPlans/${row.route_id}`, row).then((res) => {
            if (!res.error) {
                setTimeout(() => {
                    message.success({ content: res.msg, key: 'form' });
                }, 500);
                getRouteJourneyPlanList();
            } else {
                setTimeout(() => {
                    if (res.errors.length) {
                        res.errors.map((each) => {
                            message.error({ content: each.msg, key: 'form' });
                            return null;
                        });
                    } else {
                        message.error({ content: res.errors, key: 'form' });
                    }
                }, 500);
            }
        });

    };

    return (
        <>
            <Row justify='space-between' style={{ margin: "24px 0" }}>
                <Typography.Title level={3} style={{ marginBottom: "0" }}>Route Journey Plan</Typography.Title>
                <div>
                    <Button
                        onClick={showImportDrawer}
                        style={{ marginRight: '8px' }}>
                        Import
                    </Button>
                    <Button type='primary' onClick={showDrawer} >
                        Add
                    </Button>
                </div>
            </Row>
            <Table
                scroll={{ x: 3400 }}
                dataSource={routeJourneyPlanList}
                components={components}
                columns={cols}
                loading={loading}
                onChange={handleTableChange}
                pagination={{
                    showSizeChanger: true,
                    total: totalCount,
                    current: currentPage,
                    size: 'default',
                    pageSize: pageSize,
                    showTotal: (total, range) =>
                        `${range[0]} -${range[1]} of ${total} items`,
                }} />
            <RouteJourneyPlanForm handleDrawerClose={handleDrawerClose} isDrawerVisible={isDrawerVisible} getRouteJourneyPlanList={getRouteJourneyPlanList} routeJourneyPlan={selectedRouteJourneyPlan} />
            <RouteJourneyPlanImport handleDrawerClose={handleImportDrawerClose} isDrawerVisible={isImportDrawerVisible} getRouteJourneyPlanList={getRouteJourneyPlanList} />
        </>
    );

};
export default RouteJourneyPlan;
