import React from 'react';
import ProductTable from '../../../components/ProductMasters/Product/ProductTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <ProductTable />
        </ContentView>
    );
};
export default index;
