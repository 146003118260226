import React from 'react';
import CollectionsSummaryTable from '../../../components/Transactions/Collections/CollectionsSummaryTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <CollectionsSummaryTable />
        </ContentView>
    );
};
export default index;
