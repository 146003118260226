import React from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const NotFoundPage = () => {
    const history = useHistory();
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                placeContent: 'center',
                height: '100vh',
            }}
        >
            <Result
                status='404'
                title='404'
                subTitle='Sorry, the page you visited does not exist.'
                extra={
                    <Button
                        type='primary'
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        Back to Dashboard
                    </Button>
                }
            />
        </div>
    );
};

// NotFoundPage.propTypes = {};

export default NotFoundPage;
