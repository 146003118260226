import React, {
  Fragment,
  // Suspense, lazy
} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import PrivateRoute from './routing/PrivateRoute';
import NotFoundPage from './pages/404';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Area from './pages/LocaleMasters/Area';
import CurrencyConversion from './pages/LocaleMasters/CurrencyConversion';
import CountryManager from './pages/LocaleMasters/CountryManager';
import Country from './components/LocaleMasters/Country';
import Currency from './components/LocaleMasters/Currency';
import Language from './components/LocaleMasters/Language';
import Region from './pages/LocaleMasters/Region';
import Collector from './components/UserMasters/Collector';
import Customer from './pages/UserMasters/Customer';
import CustomerTypes from './pages/UserMasters/CustomerTypes';
import Manager from './pages/UserMasters/Manager';
import Salesman from './pages/UserMasters/Salesman';
import SalesmanLog from './pages/UserMasters/Salesman/log';
import User from './components/UserMasters/User';
import Company from './components/UserMasters/Company';
import Product from './pages/ProductMasters/Product';
import ProductCategory from './pages/ProductMasters/ProductCategory';
import ProductMultipack from './pages/ProductMasters/ProductMultipack';
import ProductSubcategory from './pages/ProductMasters/ProductSubcategory';
import PriceKey from './pages/PriceMasters/PriceKey';
import CustomerPrice from './pages/PriceMasters/CustomerPrice';
import RoutePrice from './pages/PriceMasters/RoutePrice';
import ProductPrice from './pages/PriceMasters/ProductPrice';
import PaymentType from './components/PriceMasters/PaymentType';
import SalesmanPrice from './components/PriceMasters/SalesmanPrice';
import RouteMaster from './pages/RouteMasters/Route';
import RouteJourneyPlan from './pages/RouteMasters/RouteJourneyPlan';
import RouteLastCode from './pages/RouteMasters/RouteLastCode';
import CustomDocumentCode from './pages/RouteMasters/CustomDocumentCode';
import Branch from './pages/RouteMasters/Branch';
import Warehouse from './pages/RouteMasters/Warehouse';

import CompanySettings from './pages/CompanySettings';

import SalesSummary from './pages/Transactions/Sales';
import SalesDetails from './pages/Transactions/Sales/details';
import CollectionsSummary from './pages/Transactions/Collections';
import CollectionsDetails from './pages/Transactions/Collections/details';
import AdvanceCollections from './pages/Transactions/AdvanceCollections';
import Settlements from './pages/Transactions/Settlements';
// import LoadSummary from './pages/Transactions/Load';
// import LoadDetails from './pages/Transactions/Load/details';
// import UnloadSummary from './pages/Transactions/Unload';
// import UnloadDetails from './pages/Transactions/Unload/details';

const Routes = () => {
  return (
    <Fragment>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateRoute
            exact
            path="/"
            component={Dashboard}
            condition={['master', 'superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/company-settings"
            component={CompanySettings}
            condition={['master', 'superadmin', 'admin']}
          />
          <PrivateRoute exact path="/area" component={Area} condition={['superadmin', 'admin']} />
          <PrivateRoute
            exact
            path="/currency-conversion"
            component={CurrencyConversion}
            condition={['master', 'superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/country-managers"
            component={CountryManager}
            condition={['master', 'superadmin', 'admin']}
          />
          <PrivateRoute exact path="/currency" component={Currency} condition={['master']} />
          <PrivateRoute exact path="/country" component={Country} condition={['master']} />
          <PrivateRoute exact path="/language" component={Language} condition={['master']} />
          <PrivateRoute exact path="/company" component={Company} condition={['master']} />
          <PrivateRoute
            exact
            path="/user"
            component={User}
            condition={['master', 'superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/region"
            component={Region}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/collector"
            component={Collector}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/customer"
            component={Customer}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/customer-types"
            component={CustomerTypes}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/manager"
            component={Manager}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/salesman"
            component={Salesman}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/salesman/log"
            component={SalesmanLog}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/product"
            component={Product}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/branch"
            component={Branch}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/product-category"
            component={ProductCategory}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/product-multipack"
            component={ProductMultipack}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/product-subcategory"
            component={ProductSubcategory}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/price-key"
            component={PriceKey}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/customer-price"
            component={CustomerPrice}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/payment-type"
            component={PaymentType}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/product-price"
            component={ProductPrice}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/route-price"
            component={RoutePrice}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/salesman-price"
            component={SalesmanPrice}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/route"
            component={RouteMaster}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/route-journey-plan"
            component={RouteJourneyPlan}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/route-last-code"
            component={RouteLastCode}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/custom-document-code"
            component={CustomDocumentCode}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/sales-summary"
            component={SalesSummary}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/sales-details"
            component={SalesDetails}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/collections-summary"
            component={CollectionsSummary}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/collections-details"
            component={CollectionsDetails}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/advance-collections"
            component={AdvanceCollections}
            condition={['superadmin', 'admin']}
          />
          <PrivateRoute
            exact
            path="/settlements"
            component={Settlements}
            condition={['superadmin', 'admin']}
          />
          {/*
          <PrivateRoute
            exact
            path="/load-summary"
            component={LoadSummary}
            condition={['superadmin', 'admin']}
          />
            */}
          {/*
          <PrivateRoute
            exact
            path="/load-details"
            component={LoadDetails}
            condition={['superadmin', 'admin']}
          />
            */}
          {/*
          <PrivateRoute
            exact
            path="/unload-summary"
            component={UnloadSummary}
            condition={['superadmin', 'admin']}
          />
            */}
          {/*
          <PrivateRoute
            exact
            path="/unload-details"
            component={UnloadDetails}
            condition={['superadmin', 'admin']}
          />
            */}
          <PrivateRoute
            exact
            path="/warehouse"
            component={Warehouse}
            condition={['superadmin', 'admin']}
          />
          <Route path="/404" component={NotFoundPage} />
          <Redirect to="/404" />
        </Switch>
      </Router>
    </Fragment>
  );
};

export default Routes;
