import React, {useState, useEffect} from 'react';
import {Form, Select, Button, Drawer, message, InputNumber} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';
import toTitleCase from 'helpers/toTitleCase';

const CurrencyConversionForm = ({
  handleDrawerClose,
  isDrawerVisible,
  getCurrencyConversionList,
  currencyConversion,
}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [currencyList, setCurrencyList] = useState([]);

  function getCurrencyList() {
    getApiWithPublicAuthToken('/api/v1/public/currencyList').then(res => {
      if (res.data && res.data.length > 0) {
        setCurrencyList(res.data);
      }
    });
  }

  useEffect(() => {
    getCurrencyList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [currencyConversion]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});

    if (currencyConversion.id) {
      updateApiWithAuthToken(
        `/api/v1/admin/currencyConversions/${currencyConversion.id}`,
        values
      ).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getCurrencyConversionList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    } else {
      postApiWithAuthToken('/api/v1/admin/currencyConversions', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getCurrencyConversionList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={currencyConversion.id ? 'Edit currency conversion' : 'Add currency conversion'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        initialValues={{remember: true}}
        onFinish={onFinish}
      >
        <Form.Item
          name="from_currency"
          label="From currency"
          initialValue={currencyConversion.from_currency_id}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select showSearch placeholder="Select a currency" optionFilterProp="children">
            {currencyList.length &&
              currencyList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {toTitleCase(item.name)}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="to_currency"
          label="To currency"
          initialValue={currencyConversion.to_currency_id}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select showSearch placeholder="Select a currency" optionFilterProp="children">
            {currencyList.length &&
              currencyList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {toTitleCase(item.name)}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="conversion"
          label="Conversion"
          initialValue={currencyConversion.conversion}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default CurrencyConversionForm;
