import React, {useState, useEffect, useRef} from 'react';
import {
  Input,
  Button,
  Table,
  Row,
  Space,
  message,
  Descriptions,
  Typography,
  DatePicker,
  Badge,
  Tag,
} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined, ReloadOutlined} from '@ant-design/icons';
import {getApiWithAuthToken} from '../../../api';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

const SalesSummary = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [salesSummaryList, setSalesSummaryList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);

  const getSalesSummaryList = ({
    limit = 10,
    page = 1,
    invoice_no = null,
    customer_name = null,
    route_name = null,
    salesman_name = null,
    field = null,
    order = null,
  }) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/salesSummary?limit=${limit}&page=${page}` +
        (startDate == null ? '' : '&start_date=' + startDate) +
        (endDate == null ? '' : '&end_date=' + endDate) +
        (invoice_no == null ? '' : '&invoice_no=' + invoice_no) +
        (customer_name == null ? '' : '&customer=' + customer_name) +
        (route_name == null ? '' : '&route=' + route_name) +
        (salesman_name == null ? '' : '&salesman=' + salesman_name) +
        (field == null ? '' : '&field=' + field) +
        (order == null ? '' : '&order=' + order)
    ).then(res => {
      setNewStartDate(moment(res.data.start_date).format('YYYY-MM-DD'));
      setNewEndDate(moment(res.data.end_date).format('YYYY-MM-DD'));
      if (!res.error && res.data.sales_summary.length) {
        setTotalCount(res.data.total_count);
        // setStartDate(moment(res.data.start_date).format("YYYY-MM-DD"));
        // setEndDate(moment(res.data.end_date).format("YYYY-MM-DD"));
        setSalesSummaryList([]);
        res.data.sales_summary.map(each =>
          setSalesSummaryList(salesSummaryList => [
            ...salesSummaryList,
            {
              key: each.id,
              invoice_no: each.invoice_no,
              ref_invoice: each.ref_invoice,
              date: each.date,
              trans_type: each.trans_type.toLowerCase(),
              sod_code: each.sod_code,
              route_code: each.route_code,
              customer_code: each.customer_code,
              customer_name: each.customer_name,
              route_name: each.route_name,
              salesman_code: each.salesman_code,
              salesman_name: each.salesman_name,
              credit_limit_days: each.credit_limit_days,
              total_sales: each.total_sales,
              total_sales_count: each.total_sales_count,
              total_expiry: each.total_expiry,
              total_expiry_count: each.total_expiry_count,
              total_damage: each.total_damage,
              total_damage_count: each.total_damage_count,
              total_foc: each.total_foc,
              total_foc_count: each.total_foc_count,
              total_gr: each.total_gr,
              total_gr_count: each.total_gr_count,
              total_discount: each.total_discount,
              invoice_total: each.invoice_total,
              vat: each.vat,
              vat_reg_no: each.vat_reg_no,
              net_amount: each.net_amount,
              paid: each.paid,
              balance: each.balance,
              status: each.status,
              void: each.void,
              remarks: each.remarks,
            },
          ])
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
          setSalesSummaryList([]);
        } else {
          if (res.errors) {
            message.error(res.errors);
            setSalesSummaryList([]);
          } else {
            message.error({content: 'Network Error!'});
            setSalesSummaryList([]);
          }
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getSalesSummaryList({limit: pageSize, page: currentPage});
  }, [startDate, endDate]);

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex} `}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // confirm();
    setCurrentPage(1);
    getSalesSummaryList({limit: pageSize, page: currentPage, [dataIndex]: selectedKeys[0]});
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    getSalesSummaryList({limit: pageSize, page: 1});
    clearFilters();
    setSearchText('');
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(filters);
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getSalesSummaryList({
      pageSize: pagination.pageSize,
      page: pagination.current,
      invoice_no: filters.invoice_no,
      customer_name: filters.customer_name,
      route_name: filters.route_name,
      salesman_name: filters.salesman_name,
      field: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      ...getColumnSearchProps('invoice_no'),
      sorter: (a, b) => (a.invoice_no ?? '').localeCompare(b.invoice_no ?? ''),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      sorter: (a, b) => (a.date ?? '').localeCompare(b.date ?? ''),
    },
    {
      title: 'Trans. Type',
      key: 'trans_type',
      render: (_, record) => <Tag>{record.trans_type.toLowerCase()}</Tag>,
      filters: [
        {
          text: 'Credit',
          value: 'Credit',
        },
        {
          text: 'Cash',
          value: 'Cash',
        },
        {
          text: 'Temporary Credit',
          value: 'Temporary Credit',
        },
      ],
      onFilter: (value, record) => record.trans_type.toLowerCase() == value.toLowerCase(),
    },
    {
      title: 'Net Amount',
      dataIndex: 'net_amount',
      key: 'net_amount',
      sorter: (a, b) => (a.net_amount ?? '').localeCompare(b.net_amount ?? ''),
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      ...getColumnSearchProps('customer_name'),
      sorter: (a, b) => (a.customer_name ?? '').localeCompare(b.customer_name ?? ''),
    },
    {
      title: 'Route',
      dataIndex: 'route_name',
      key: 'route_name',
      ...getColumnSearchProps('route_name'),
      sorter: (a, b) => (a.route_name ?? '').localeCompare(b.route_name ?? ''),
    },
    {
      title: 'Salesman',
      dataIndex: 'salesman_name',
      key: 'salesman_name',
      ...getColumnSearchProps('salesman_name'),
      sorter: (a, b) => (a.salesman_name ?? '').localeCompare(b.salesman_name ?? ''),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <>
          <Tag color={record.status == 'printed' ? 'green' : 'red'}>
            {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
          </Tag>
        </>
      ),
    },
    {
      title: 'Collection',
      key: 'collection',
      dataIndex: 'collection',
      render: (_, record) => (record.balance > 0 ? <Tag color="orange">Pending</Tag> : ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button
            size="small"
            onClick={() => {
              history.push(`/sales-details?sales_code=${record.invoice_no}`);
            }}
          >
            View Details
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Sales Summary
        </Typography.Title>
        <div>
          <Button
            type="primary"
            style={{marginRight: '16px'}}
            onClick={() => {
              setStartDate(
                moment(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 7
                  )
                ).format('YYYY-MM-DD')
              );
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              setCurrentPage(1);
            }}
          >
            Last Week
          </Button>
          <Button
            type="primary"
            style={{marginRight: '16px'}}
            onClick={() => {
              setStartDate(moment(new Date()).format('YYYY-MM-DD'));
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              setCurrentPage(1);
            }}
          >
            Today
          </Button>
          <DatePicker.RangePicker
            value={[
              moment(newStartDate).isValid()
                ? moment.utc(newStartDate).local()
                : moment.utc(startDate).local(),
              moment(newEndDate).isValid()
                ? moment.utc(newEndDate).local()
                : moment.utc(endDate).local(),
            ]}
            onChange={(dates, dateStrings) => {
              setNewStartDate(dateStrings[0]);
              setNewEndDate(dateStrings[1]);
            }}
          />
          <Button
            type="primary"
            style={{marginLeft: '16px'}}
            onClick={() => {
              setStartDate(newStartDate);
              setEndDate(newEndDate);
              setCurrentPage(1);
              // getSalesSummaryList(pageSize, 1);
            }}
          >
            <ReloadOutlined />
          </Button>
        </div>
      </Row>
      <Table
        scroll={{x: 0}}
        dataSource={salesSummaryList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: currentPage,
          size: 'default',
          pageSize: pageSize,
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
        expandable={{
          expandedRowRender: record => (
            <Descriptions bordered style={{backgroundColor: '#fff'}}>
              <Descriptions.Item label="Credit Limit Days">
                {record.credit_limit_days}
              </Descriptions.Item>
              {record.total_sales_count > 0 ? (
                <>
                  <Descriptions.Item label="Total Sales">{record.total_sales}</Descriptions.Item>
                  <Descriptions.Item label="Total Sales Count">
                    {record.total_sales_count}
                  </Descriptions.Item>
                </>
              ) : null}
              {record.total_expiry_count > 0 ? (
                <>
                  <Descriptions.Item label="Total Expiry">{record.total_expiry}</Descriptions.Item>
                  <Descriptions.Item label="Total Expiry Count">
                    {record.total_expiry_count}
                  </Descriptions.Item>
                </>
              ) : null}
              {record.total_damage_count > 0 ? (
                <>
                  <Descriptions.Item label="Total Damage">{record.total_damage}</Descriptions.Item>
                  <Descriptions.Item label="Total Damage Count">
                    {record.total_damage_count}
                  </Descriptions.Item>
                </>
              ) : null}
              {record.total_foc > 0 ? (
                <>
                  <Descriptions.Item label="Total FOC">{record.total_foc}</Descriptions.Item>
                  <Descriptions.Item label="Total FOC Count">
                    {record.total_foc_count}
                  </Descriptions.Item>
                </>
              ) : null}
              {record.total_gr > 0 ? (
                <>
                  <Descriptions.Item label="Total GR">{record.total_gr}</Descriptions.Item>
                  <Descriptions.Item label="Total GR Count">
                    {record.total_gr_count}
                  </Descriptions.Item>
                </>
              ) : null}
              <Descriptions.Item label="Invoice Total">{record.invoice_total}</Descriptions.Item>
              <Descriptions.Item label="Total Discount">{record.total_discount}</Descriptions.Item>
              <Descriptions.Item label="VAT">{record.vat}</Descriptions.Item>
              <Descriptions.Item label="VAT Reg. No.">{record.vat_reg_no && ''}</Descriptions.Item>
              <Descriptions.Item label="Status">
                <Badge
                  status={record.status == 'printed' ? 'success' : 'error'}
                  text={record.status.charAt(0).toUpperCase() + record.status.slice(1)}
                />
              </Descriptions.Item>
              {record.void ? (
                <Descriptions.Item label="Void">
                  <Badge status="error" text="Voided" />
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Collected">{record.paid}</Descriptions.Item>
              <Descriptions.Item label="Balance">
                <Badge status={record.balance > 0 ? 'warning' : ''} text={record.balance} />
              </Descriptions.Item>
              <Descriptions.Item label="Remarks">{record.remarks && ''}</Descriptions.Item>
            </Descriptions>
          ),
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
      />
    </>
  );
};
export default SalesSummary;
