import React from 'react';
import RouteLastCodeTable from '../../../components/RouteMasters/RouteLastCode/RouteLastCodeTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
  return (
    <ContentView>
      <RouteLastCodeTable />
    </ContentView>
  );
};
export default index;
