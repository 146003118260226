import React from 'react';
import SiderView from './SiderView';
import {connect} from 'react-redux';
import HeaderView from './HeaderView';
import {Layout} from 'antd';
const {Content} = Layout;

const ContentView = ({children, user}) => {
  return (
    <Layout>
      <HeaderView />
      <Layout>
        <SiderView width={200} />
        <Layout>
          <Content style={{padding: 24, margin: 0, minHeight: '100vh'}}>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(ContentView);
