import React, {useState, useEffect} from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Drawer,
  message,
  Upload,
  Checkbox,
  Typography,
  InputNumber,
} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateWithFileApiWithAuthToken,
  getApiWithAuthToken,
} from '../../../api';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import toTitleCase from 'helpers/toTitleCase';

const SalesmanForm = ({handleDrawerClose, isDrawerVisible, getSalesmanList, salesman}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [languageList, setLanguageList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [enableChangePassword, setEnableChangePassword] = useState(true);
  const [profileImageSrc, setProfileImageSrc] = useState();

  function getLanguageList() {
    getApiWithPublicAuthToken('/api/v1/public/languageList').then(res => {
      if (res.data && res.data.length > 0) {
        res.data.map(each => (each.name = toTitleCase(each.name)));
        setLanguageList(res.data);
      }
    });
  }
  function getBranchList() {
    getApiWithAuthToken('/api/v1/admin/branches').then(res => {
      if (res.data && res.data.length > 0) {
        setBranchList(res.data);
      }
    });
  }
  function getRouteList() {
    getApiWithAuthToken('/api/v1/admin/routes').then(res => {
      if (res.data && res.data.length > 0) {
        setRouteList(res.data);
      }
    });
  }

  useEffect(() => {
    getLanguageList();
    getBranchList();
    getRouteList();
  }, []);

  useEffect(() => {
    form.resetFields();
    if (salesman.id) {
      setEnableChangePassword(false);
    } else {
      setEnableChangePassword(true);
    }
    if (salesman.profile_image) setProfileImageSrc(salesman.profile_image);
    else setProfileImageSrc();
  }, [salesman]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});
    if (salesman.id) {
      let formData = new FormData();

      for (const property in values) {
        if (property !== 'profile_image') formData.append(property, values[property]);
      }

      if (values?.profile_image?.fileList[0]?.originFileObj) {
        formData.append('profile_image', values.profile_image?.fileList[0].originFileObj);
      }

      updateWithFileApiWithAuthToken(`/api/v1/admin/salesmen/${salesman.id}`, formData).then(
        res => {
          if (!res.error) {
            setTimeout(() => {
              message.success({content: res.msg, key: 'form'});
            }, 500);
            handleDrawerClose();
            getSalesmanList();
          } else {
            setTimeout(() => {
              if (res.errors.length) {
                res.errors.map(each => {
                  message.error({
                    content: each.msg,
                    key: 'form',
                  });
                  return null;
                });
              } else {
                message.error({content: res.errors, key: 'form'});
              }
            }, 500);
          }
        }
      );
    } else {
      let formData = new FormData();
      formData.append('profile_image', values.profile_image?.fileList[0].originFileObj);

      for (const property in values) {
        formData.append(property, values[property]);
      }

      postApiWithAuthToken('/api/v1/admin/salesmen', formData).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getSalesmanList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({
                  content: each.msg,
                  key: 'form',
                });
                return null;
              });
            } else {
              message.error({
                content: res.errors,
                key: 'form',
              });
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={salesman.id ? 'Edit salesman' : 'Add salesman'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form layout="vertical" hideRequiredMark form={form} onFinish={onFinish}>
        <Form.Item
          label="Profile Image"
          name="profile_image"
          // rules={[{ required: true, message: 'This field is required' }]}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            maxCount={1}
            beforeUpload={file => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                var src = reader.result;
                setProfileImageSrc(src);
              };
              return false;
            }}
          >
            {profileImageSrc ? (
              <img src={profileImageSrc} alt="avatar" style={{width: '100%'}} />
            ) : (
              <div>
                {profileImageSrc ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{marginTop: 8}}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          initialValue={salesman.email}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          initialValue={salesman.name}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        {salesman.id && (
          <Form.Item>
            <Checkbox
              checked={enableChangePassword}
              onChange={e => setEnableChangePassword(e.target.checked)}
            >
              Change password
            </Checkbox>
          </Form.Item>
        )}
        {/* {enableChangeImage &&
                    <Form.Item
                        label="Profile Image"
                        name="profile_image"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <Upload
                            beforeUpload={() => false}
                            listType="picture"
                            className="upload-list-inline"
                            maxCount={1}
                            showUploadList={{
                                showRemoveIcon: false
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                } */}
        {enableChangePassword && (
          <>
            <Form.Item
              name="password"
              label="Password"
              initialValue={salesman.password}
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                {
                  min: 6,
                  message: 'Password must be minimum 6 characters.',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              dependencies={['password']}
              initialValue={salesman.confirm_password}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('The two passwords that you entered do not match!')
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </>
        )}
        {/* <Form.Item
                    name="code"
                    label="Code"
                    initialValue={salesman.code}
                    rules={[{ required: true, message: 'This field is required' }]}
                >
                    <Input />
                </Form.Item> */}
        <Form.Item
          name="lang"
          label="Language"
          initialValue={salesman ? salesman.lang_id : null}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select showSearch placeholder="Select a language" optionFilterProp="children">
            {languageList.length &&
              languageList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="job_title" label="Job Title" initialValue={salesman.job_title}>
          <Input />
        </Form.Item>
        <Form.Item
          name="def_route"
          label="Default Route"
          initialValue={
            routeList.find(def_route => def_route.name === salesman.def_route)
              ? routeList.find(def_route => def_route.name === salesman.def_route).id
              : null
          }
        >
          <Select showSearch placeholder="Select a route" optionFilterProp="children">
            {routeList.length &&
              routeList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="temp_credit_amt"
          label="Temperory Credit Amount"
          initialValue={salesman.temp_credit_amt}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="temp_credit_days"
          label="Temperory Credit Days"
          initialValue={salesman.temp_credit_days}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="temp_credit_given"
          label="Temperory Credit Given"
          initialValue={salesman.temp_credit_given}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="branch"
          label="Branch"
          initialValue={
            branchList.find(branch => branch.name === salesman.branch)
              ? branchList.find(branch => branch.name === salesman.branch).id
              : null
          }
        >
          <Select showSearch placeholder="Select a branch" optionFilterProp="children">
            {branchList.length &&
              branchList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="cash_in_hand" label="Cash In Hand" initialValue={salesman.cash_in_hand}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="credit_given" label="Credit Given" initialValue={salesman.credit_given}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="credit_sales_target"
          label="Target credit sales amount per SOD"
          initialValue={salesman.credit_sales_target}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="cash_sales_target"
          label="Target cash sales amount per SOD"
          initialValue={salesman.cash_sales_target}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="visit_target" label="Target number of customers to visit per SOD" initialValue={salesman.visit_target}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="collection_target"
          label="Target collection amount per SOD"
          initialValue={salesman.collection_target}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="enable_edit_price"
          valuePropName="checked"
          initialValue={salesman.enable_edit_price}
        >
          <Checkbox>Allow price edit of items</Checkbox>
        </Form.Item>
        <Form.Item
          name="enable_discount"
          valuePropName="checked"
          initialValue={salesman.enable_discount}
        >
          <Checkbox>Enable discount in sales transaction</Checkbox>
        </Form.Item>
        {/* <Form.Item
          name="enable_neg_sales"
          valuePropName="checked"
          initialValue={salesman.enable_neg_sales}
        >
          <Checkbox>Enable neg sales</Checkbox>
        </Form.Item> */}
        <Form.Item
          name="enable_void_option"
          valuePropName="checked"
          initialValue={salesman.enable_void_option}
        >
          <Checkbox>Enable void option</Checkbox>
        </Form.Item>
        <Form.Item
          name="discount_in_collection"
          valuePropName="checked"
          initialValue={salesman.discount_in_collection}
        >
          <Checkbox>Enable discount in collection</Checkbox>
        </Form.Item>
        <Form.Item
          name="show_rjp_customers_only"
          valuePropName="checked"
          initialValue={salesman.show_rjp_customers_only}
        >
          <Checkbox>Show only route journey plan listed customers</Checkbox>
        </Form.Item>
        <Form.Item
          name="max_discount_perc"
          valuePropName="checked"
          label="Max allowed discount(in percentage)"
          initialValue={salesman.max_discount_perc}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="discount_in_amount"
          valuePropName="checked"
          initialValue={salesman.discount_in_amount}
        >
          <Checkbox>Enable option to enter the discount in amount</Checkbox>
        </Form.Item>
        <Form.Item
          name="enable_line_item_discount"
          valuePropName="checked"
          initialValue={salesman.enable_line_item_discount}
        >
          <Checkbox>Enable option to enter discount per line item</Checkbox>
        </Form.Item>
        <Form.Item
          name="select_invoice_in_collection"
          valuePropName="checked"
          initialValue={salesman.select_invoice_in_collection}
        >
          <Checkbox>Enable selection of invoice in collection transaction</Checkbox>
        </Form.Item>
        <Form.Item
          name="enable_simplified_tax"
          valuePropName="checked"
          initialValue={salesman.enable_simplified_tax}
        >
          <Checkbox>Enable simplified tax invoice in sales transaction</Checkbox>
        </Form.Item>
        <Form.Item
          name="allow_below_cost_price_sales"
          valuePropName="checked"
          initialValue={salesman.allow_below_cost_price_sales}
        >
          <Checkbox>Enable salesman to edit price to below cost price in sales transaction</Checkbox>
        </Form.Item>
        <Typography.Title level={5} style={{marginBottom: '12px'}}>
          Transaction modules
        </Typography.Title>
        <Form.Item
          name="trans_mod_sale"
          valuePropName="checked"
          initialValue={salesman.trans_mods?.charAt(0) === '1'}
        >
          <Checkbox>Sale</Checkbox>
        </Form.Item>
        <Form.Item
          name="trans_mod_collection"
          valuePropName="checked"
          initialValue={salesman.trans_mods?.charAt(1) === '1'}
        >
          <Checkbox>Collection</Checkbox>
        </Form.Item>
        <Form.Item
          name="trans_mod_settlement"
          valuePropName="checked"
          initialValue={salesman.trans_mods?.charAt(2) === '1'}
        >
          <Checkbox>Settlement</Checkbox>
        </Form.Item>
        <Form.Item
          name="trans_mod_load"
          valuePropName="checked"
          initialValue={salesman.trans_mods?.charAt(3) === '1'}
        >
          <Checkbox>Load</Checkbox>
        </Form.Item>
        <Form.Item
          name="trans_mod_unload"
          valuePropName="checked"
          initialValue={salesman.trans_mods?.charAt(4) === '1'}
        >
          <Checkbox>Unload</Checkbox>
        </Form.Item>
        <Form.Item
          name="trans_mod_advance_collection"
          valuePropName="checked"
          initialValue={salesman.trans_mods?.charAt(5) === '1'}
        >
          <Checkbox>Advance Collection</Checkbox>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default SalesmanForm;
