import React from 'react';
import CustomerTypesTable from '../../../components/UserMasters/CustomerTypes/CustomerTypesTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <CustomerTypesTable />
        </ContentView>
    );
};
export default index;
