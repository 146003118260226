import React, {useState, useEffect} from 'react';
import {Button, Table, Row, message, Popconfirm, Typography} from 'antd';
import {getApiWithAuthToken} from '../../../api';
import PriceKeyForm from './PriceKeyForm';
import {useColumnSearchProps, handleDeleteClick, handleTableChange} from '../../../helpers/table';

const PriceKey = () => {
  // Table states
  const [loading, setLoading] = useState(true);
  const [priceKeyList, setPriceKeyList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: 10,
    page: 1,
    code: null,
    ref_code: null,
    description: null,
    multipack: null,
    field: null,
    order: null,
  });

  const getPriceKeyList = (qp = queryParams) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/priceKeys?limit=${qp.limit}&page=${qp.page}` +
        (qp.description ? `&description=${qp.description}` : '') +
        (qp.code ? `&code=${qp.code}` : '') +
        (qp.ref_code ? `&ref_code=${qp.ref_code}` : '') +
        (qp.multipack ? `&multipack=${qp.multipack}` : '') +
        (qp.field ? `&field=${qp.field}` : '') +
        (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      if (!res.error && res.data?.price_keys.length) {
        setTotalCount(res.data.total_count);
        setPriceKeyList(
          res.data.price_keys.map(each => ({
            id: each.id,
            key: each.id,
            code: each.code,
            ref_code: each.ref_code,
            description: each.description,
            multipack: each.multipack,
            quantity: each.quantity,
            cost_price: each.cost_price,
            min_sales: each.min_sales,
            max_discount: each.max_discount,
            sales: each.sales,
            currency: each.currency,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({content: 'Network Error!'});
        }
        setPriceKeyList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getPriceKeyList(queryParams);
  }, []);

  // Edit function

  const [selectedPriceKey, setSelectedPriceKey] = useState({});
  const handleEditClick = record => {
    setSelectedPriceKey(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedPriceKey({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...useColumnSearchProps('code', queryParams, setQueryParams, getPriceKeyList),
      sorter: true,
    },
    {
      title: 'Ref Code',
      dataIndex: 'ref_code',
      key: 'ref_code',
      ...useColumnSearchProps('ref_code', queryParams, setQueryParams, getPriceKeyList),
      sorter: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ...useColumnSearchProps('description', queryParams, setQueryParams, getPriceKeyList),
      sorter: false,
    },
    {
      title: 'Multipack ',
      dataIndex: 'multipack',
      key: 'multipack',
      ...useColumnSearchProps('multipack', queryParams, setQueryParams, getPriceKeyList),
      sorter: false,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: true,
    },
    {
      title: 'Cost Price',
      dataIndex: 'cost_price',
      key: 'cost_price',
      sorter: true,
    },
    {
      title: 'Sale Price',
      dataIndex: 'sales',
      key: 'sales',
      sorter: true,
    },
    {
      title: 'Min. Sale Price',
      dataIndex: 'min_sales',
      key: 'min_sales',
      sorter: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
          <span> </span>
          <Popconfirm
            title="Are you sure to delete this price key?"
            onConfirm={() =>
              handleDeleteClick(record.id, '/api/v1/admin/priceKeys/', () =>
                getPriceKeyList(queryParams)
              )
            }
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Price Key
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table
        scroll={{x: 0}}
        dataSource={priceKeyList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange(queryParams, setQueryParams, getPriceKeyList)}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: queryParams.page,
          pageSize: queryParams.limit,
          size: 'default',
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
      />
      <PriceKeyForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getPriceKeyList={getPriceKeyList}
        priceKey={selectedPriceKey}
      />
    </>
  );
};

export default PriceKey;
