import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Drawer, message, Checkbox, Row, Col } from 'antd';
import { postApiWithAuthToken, getApiWithAuthToken, getApiWithPublicAuthToken, updateApiWithAuthToken } from '../../../api';

const WarehouseForm = ({ handleDrawerClose, isDrawerVisible, getWarehouseList, warehouse }) => {
    const [form] = Form.useForm();

    // Get dropdown data 
    const [managerList, setManagerList] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    function getManagerList() {
        getApiWithAuthToken('/api/v1/admin/managers').then((res) => {
            if (res.data && res.data.length > 0) {
                setManagerList(res.data);
            }
        });
    }
    function getLanguageList() {
        getApiWithPublicAuthToken('/api/v1/public/languageList').then((res) => {
            if (res.data && res.data.length > 0) {
                setLanguageList(res.data);
            }
        });
    }
    function getCurrencyList() {
        getApiWithPublicAuthToken('/api/v1/public/currencyList').then((res) => {
            if (res.data && res.data.length > 0) {
                setCurrencyList(res.data);
            }
        });
    }
    function getCountryList() {
        getApiWithPublicAuthToken('/api/v1/public/countryList').then((res) => {
            if (res.data && res.data.length > 0) {
                setCountryList(res.data);
            }
        });
    }
    function getRegionList() {
        getApiWithPublicAuthToken('/api/v1/admin/regions').then((res) => {
            if (res.data && res.data.length > 0) {
                setRegionList(res.data);
            }
        });
    }
    function getAreaList() {
        getApiWithPublicAuthToken('/api/v1/admin/areas').then((res) => {
            if (res.data && res.data.length > 0) {
                setAreaList(res.data);
            }
        });
    }

    useEffect(() => {
        getManagerList();
        getLanguageList();
        getCurrencyList();
        getCountryList();
        getRegionList();
        getAreaList();
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [warehouse]);

    // Form Submit 

    const handleSubmit = () => {
        form.submit();
    };

    const onFinish = (values) => {
        message.loading({ content: 'Processing...', key: 'form' });
        if (warehouse.id) {
            updateApiWithAuthToken(`/api/v1/admin/warehouses/${warehouse.id}`, values).then((res) => {
                if (!res.error) {
                    setTimeout(() => {
                        message.success({ content: res.msg, key: 'form' });
                    }, 500);
                    handleDrawerClose();
                    getWarehouseList();
                } else {
                    setTimeout(() => {
                        if (res.errors.length) {
                            res.errors.map((each) => {
                                message.error({ content: each.msg, key: 'form' });
                                return null;
                            });
                        } else {
                            message.error({ content: res.errors, key: 'form' });
                        }
                    }, 500);
                }
            });
        } else {
            postApiWithAuthToken('/api/v1/admin/warehouses', values).then((res) => {
                if (!res.error) {
                    setTimeout(() => {
                        message.success({ content: res.msg, key: 'form' });
                    }, 500);
                    handleDrawerClose();
                    getWarehouseList();
                } else {
                    setTimeout(() => {
                        if (res.errors.length) {
                            res.errors.map((each) => {
                                message.error({ content: each.msg, key: 'form' });
                                return null;
                            });
                        } else {
                            message.error({ content: res.errors, key: 'form' });
                        }
                    }, 500);
                }
            });
        }
    };

    return (
        <Drawer
            title={warehouse.id ? "Edit warehouse" : "Add warehouse"}
            width={480}
            onClose={handleDrawerClose}
            visible={isDrawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div style={{ textAlign: 'right', }}>
                    <Button onClick={handleDrawerClose} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} type="primary">
                        Submit
                    </Button>
                </div>
            }
        >
            <Form layout="vertical"
                hideRequiredMark
                form={form}
                onFinish={onFinish} >
                <Form.Item
                    name="name"
                    label="Name"
                    initialValue={warehouse.name}
                    rules={[{ required: true, message: 'This field is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="ref_code"
                    label="Ref Code"
                    initialValue={warehouse.ref_code}
                    rules={[{ required: true, message: 'This field is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="manager"
                    label="Manager"
                    initialValue={managerList.find(manager => manager.name === warehouse.manager) ? managerList.find(manager => manager.name === warehouse.manager).id : null}
                    rules={[{ required: true, message: 'This field is required' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a manager"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {managerList.length &&
                            managerList.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="lang"
                    label="Language"
                    initialValue={languageList.find(language => language.name === warehouse.lang) ? languageList.find(language => language.name === warehouse.lang).id : null}
                >
                    <Select
                        showSearch
                        placeholder="Select a language"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {languageList.length &&
                            languageList.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="currency"
                    label="Currency"
                    initialValue={currencyList.find(currency => currency.name === warehouse.currency) ? currencyList.find(currency => currency.name === warehouse.currency).id : null}
                >
                    <Select
                        showSearch
                        placeholder="Select a currency"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {currencyList.length &&
                            currencyList.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="country"
                    label="Country"
                    initialValue={countryList.find(country => country.name === warehouse.country) ? countryList.find(country => country.name === warehouse.country).id : null}
                >
                    <Select
                        showSearch
                        placeholder="Select a country"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {countryList.length &&
                            countryList.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="region"
                    label="Region"
                    initialValue={regionList.find(region => region.name === warehouse.region) ? regionList.find(region => region.name === warehouse.region).id : null}
                >
                    <Select
                        showSearch
                        placeholder="Select a region"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {regionList.length &&
                            regionList.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="area"
                    label="Area"
                    initialValue={areaList.find(area => area.name === warehouse.area) ? areaList.find(area => area.name === warehouse.area).id : null}
                >
                    <Select
                        showSearch
                        placeholder="Select a area"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {areaList.length &&
                            areaList.map((item) => (
                                <Select.Option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="address"
                    label="Address"
                    initialValue={warehouse.address}
                >
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Drawer >
    );
};
export default WarehouseForm;
