import React from 'react';
import RouteTable from '../../../components/RouteMasters/Route/RouteTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <RouteTable />
        </ContentView>
    );
};
export default index;
