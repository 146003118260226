import React, {useState, useEffect, useRef} from 'react';
import {Input, Button, Table, Row, Space, message, Popconfirm, Typography} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import {getApiWithAuthToken, deleteApiWithAuthToken} from '../../../api';
import RouteForm from './RouteForm';

const Route = () => {
  const [routeList, setRouteList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getRouteList = () => {
    getApiWithAuthToken('/api/v1/admin/routes').then(res => {
      if (!res.error && res.data?.length) {
        setRouteList(
          res.data.map(each => ({
            id: each.id,
            key: each.id,
            code: each.code,
            name: each.name,
            visit_days: each.visit_days,
            def_start_day: each.def_start_day,
            multi_day: each.multi_day,
            multi_sod: each.multi_sod,
            salesman: each.salesman,
            branch: each.branch,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({content: 'Network Error!'});
        }
        setRouteList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getRouteList();
  }, []);

  // Delete function
  function handleDeleteClick(id) {
    message.loading({content: 'Processing...', key: id});
    deleteApiWithAuthToken(`/api/v1/admin/routes/${id}`).then(res => {
      message.loading({content: 'Loading...', duration: 0.5});
      if (res.error) {
        setTimeout(() => {
          if (res.errors.errno === 1451) {
            message.error({
              content: 'Could not delete!, The route is being referred in another place',
              key: id,
            });
          } else {
            message.error({
              content: res.errors.sqlMessage,
              key: id,
            });
          }
        }, 1000);
      } else {
        setTimeout(() => {
          message.success({
            content: 'Deleted!',
            key: id,
          });
        }, 1000);
        getRouteList();
      }
    });
  }

  // Edit function

  const [selectedRoute, setSelectedRoute] = useState({});
  const handleEditClick = record => {
    setSelectedRoute(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedRoute({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...getColumnSearchProps('code'),
      sorter: (a, b) => (a.code ?? '').localeCompare(b.code ?? ''),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
      title: 'Visit Days',
      dataIndex: 'visit_days',
      key: 'visit_days',
      ...getColumnSearchProps('visit_days'),
      sorter: (a, b) => (a.visit_days ?? '').localeCompare(b.visit_days ?? ''),
    },
    {
      title: 'Salesman',
      dataIndex: 'salesman',
      key: 'salesman',
      className: 'table-col-capitalize',
      ...getColumnSearchProps('salesman'),
      sorter: (a, b) => (a.salesman ?? '').localeCompare(b.salesman ?? ''),
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      className: 'table-col-capitalize',
      ...getColumnSearchProps('branch'),
      sorter: (a, b) => (a.branch ?? '').localeCompare(b.branch ?? ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>{' '}
          <span> </span>
          <Popconfirm
            title="Are you sure to delete this route?"
            onConfirm={() => handleDeleteClick(record.id)}
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Route
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table scroll={{x: 0}} dataSource={routeList} columns={columns} loading={loading} />
      <RouteForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getRouteList={getRouteList}
        route={selectedRoute}
      />
    </>
  );
};
export default Route;
