import React from 'react';
import Settlements from '../../../components/Transactions/Settlements/SettlementsTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
  return (
    <ContentView>
      <Settlements />
    </ContentView>
  );
};
export default index;
