import React, {useState, useEffect} from 'react';
import Content from '../../components/Layouts/ContentView';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
  message,
  Typography,
  // Checkbox,
  Switch,
} from 'antd';
import {
  updateWithFileApiWithAuthToken,
  getApiWithAuthToken,
  getApiWithPublicAuthToken,
} from '../../api';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';

const CompanySettings = () => {
  const [form] = Form.useForm();

  const [languageList, setLanguageList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [companySettings, setCompanySettings] = useState({});
  const [logoSrc, setLogoSrc] = useState();

  useEffect(() => {
    getLanguageList();
    getCurrencyList();
    getCountryList();
    getBranchList();
    getCompanySettings();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [companySettings, languageList, currencyList, countryList, branchList]);

  function getCompanySettings() {
    getApiWithAuthToken('/api/v1/admin/getCompanyDetails').then(res => {
      if (res.data && res.data.length > 0) {
        setCompanySettings(res.data[0]);

        if (res.data[0].logo) setLogoSrc(res.data[0].logo);
      }
    });
  }

  function getLanguageList() {
    getApiWithPublicAuthToken('/api/v1/public/languageList').then(res => {
      if (res.data && res.data.length > 0) {
        setLanguageList(res.data);
      }
    });
  }

  function getCurrencyList() {
    getApiWithPublicAuthToken('/api/v1/public/currencyList').then(res => {
      if (res.data && res.data.length > 0) {
        setCurrencyList(res.data);
      }
    });
  }

  function getCountryList() {
    getApiWithPublicAuthToken('/api/v1/public/countryList').then(res => {
      if (res.data && res.data.length > 0) {
        setCountryList(res.data);
      }
    });
  }

  function getBranchList() {
    getApiWithAuthToken('/api/v1/admin/branches').then(res => {
      if (res.data && res.data.length > 0) {
        setBranchList(res.data);
      }
    });
  }

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});

    let formData = new FormData();

    for (const property in values) {
      if (property !== 'logo') formData.append(property, values[property]);
    }

    if (values?.logo?.fileList[0]?.originFileObj) {
      formData.append('logo', values.logo.fileList[0].originFileObj);
    }

    updateWithFileApiWithAuthToken('/api/v1/admin/updateCompanyDetails', formData).then(res => {
      if (!res.error) {
        message.success({content: res.msg, key: 'form'});
      } else {
        if (res.errors.length) {
          res.errors.map(each => {
            message.error({content: each.msg, key: 'form'});
            return null;
          });
        } else {
          message.error({content: res.errors, key: 'form'});
        }
      }
    });
  };

  return (
    <Content>
      <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
        <Row gutter={16} justify={'center'}>
          <Col sm={{span: 18}} md={{span: 16}} lg={{span: 14}}>
            <Typography.Title level={3} style={{marginBottom: '32px'}}>
              Company Settings
            </Typography.Title>
            <Form
              name="basic"
              layout="vertical"
              hideRequiredMark
              form={form}
              initialValues={{remember: true}}
              onFinish={onFinish}
            >
              <Form.Item
                label="Logo"
                name="logo"
                // rules={[{ required: true, message: 'This field is required' }]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  maxCount={1}
                  beforeUpload={file => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      var src = reader.result;
                      setLogoSrc(src);
                    };
                    return false;
                  }}
                >
                  {logoSrc ? (
                    <img src={logoSrc} alt="avatar" style={{width: '100%'}} />
                  ) : (
                    <div>
                      {logoSrc ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{marginTop: 8}}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>

              {/* <Form.Item
                        label="Logo"
                        name="logo"
                    // rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <Upload
                            beforeUpload={() => false}
                            listType="picture"
                            className="upload-list-inline"
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item> */}
              <Form.Item
                label="Name"
                name="name"
                initialValue={companySettings.name}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Def Language"
                    name="lang"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                    initialValue={
                      languageList.find(lang => lang.name === companySettings.lang)
                        ? languageList.find(lang => lang.name === companySettings.lang).id
                        : null
                    }
                  >
                    <Select
                      showSearch
                      placeholder="Select a language"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {languageList.length &&
                        languageList.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Def Currency"
                    name="currency"
                    initialValue={
                      currencyList.find(currency => currency.name === companySettings.currency)
                        ? currencyList.find(currency => currency.name === companySettings.currency)
                            .id
                        : null
                    }
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a currency"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {currencyList.length &&
                        currencyList.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="HO Country"
                    name="ho_country"
                    initialValue={
                      countryList.find(ho_country => ho_country.name === companySettings.ho_country)
                        ? countryList.find(
                            ho_country => ho_country.name === companySettings.ho_country
                          ).id
                        : null
                    }
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a country"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {countryList.length &&
                        countryList.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="HO Branch"
                    name="ho_branch"
                    initialValue={
                      branchList.find(ho_branch => ho_branch.name === companySettings.ho_branch)
                        ? branchList.find(ho_branch => ho_branch.name === companySettings.ho_branch)
                            .id
                        : null
                    }
                  >
                    <Select
                      showSearch
                      placeholder="Select a country"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {branchList.length &&
                        branchList.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Def Address"
                name="ho_address"
                initialValue={companySettings.ho_address}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="VAT"
                    name="vat"
                    initialValue={companySettings.vat}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="VAT Reg Number"
                    name="vat_reg_no"
                    initialValue={companySettings.vat_reg_no}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Default display duration for transaction reports (in months)"
                    name="def_reports_disp_duration"
                    initialValue={companySettings.def_reports_disp_duration ?? 1}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Decimal Places"
                    name="decimal_places"
                    initialValue={companySettings.decimal_places ?? 2}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="company_country"
                label="Company countries"
                initialValue={
                  countryList
                    .filter(country => companySettings.company_countries?.includes(country.id))
                    .map(country => country['id']) ?? null
                }
              >
                <Select
                  showSearch
                  placeholder="Select countries"
                  optionFilterProp="children"
                  mode="multiple"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {countryList.length &&
                    countryList.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Typography.Title
                level={5}
                style={{
                  marginBottom: '24px',
                  marginTop: '24px',
                }}
              >
                Mobile Configurations
              </Typography.Title>

              <div
                style={{
                  backgroundColor: 'white',
                  padding: '12px',
                }}
              >
                <Row gutter={16} justify={'space-between'} align={'middle'}>
                  <Col>Show the last sold price in price edit screen</Col>
                  <Col>
                    <Form.Item
                      name="show_last_sold_price"
                      valuePropName="checked"
                      initialValue={companySettings.show_last_sold_price}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row gutter={16} justify={'space-between'} align={'middle'}>
                  <Col>Default the last sold price in the price list for an item</Col>
                  <Col>
                    <Form.Item
                      name="def_last_sold_price_in_sales"
                      valuePropName="checked"
                      initialValue={companySettings.def_last_sold_price_in_sales}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Show price in load transaction</Col>
                  <Col>
                    <Form.Item
                      name="show_price_load"
                      valuePropName="checked"
                      initialValue={companySettings.show_price_load}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Show price in load picklist print</Col>
                  <Col>
                    <Form.Item
                      name="show_price_picklist"
                      valuePropName="checked"
                      initialValue={companySettings.show_price_picklist}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Show the quantity in hand during unload transaction</Col>
                  <Col>
                    <Form.Item
                      name="show_qih_unload"
                      valuePropName="checked"
                      initialValue={companySettings.show_qih_unload}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable category hide option</Col>
                  <Col>
                    <Form.Item
                      name="enable_category_hide_option"
                      valuePropName="checked"
                      initialValue={companySettings.enable_category_hide_option}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Show category by default</Col>
                  <Col>
                    <Form.Item
                      name="def_category_show"
                      valuePropName="checked"
                      initialValue={companySettings.def_category_show}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable print preview in collection transaction</Col>
                  <Col>
                    <Form.Item
                      name="enable_print_prev_collection"
                      valuePropName="checked"
                      initialValue={companySettings.enable_print_prev_collection}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable collection print in cash sales transaction</Col>
                  <Col>
                    <Form.Item
                      name="collection_print_in_cash_sales"
                      valuePropName="checked"
                      initialValue={companySettings.collection_print_in_cash_sales}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable separate collection screen for cash sales transaction</Col>
                  <Col>
                    <Form.Item
                      name="separate_collection_screen_cash_sales"
                      valuePropName="checked"
                      initialValue={companySettings.separate_collection_screen_cash_sales}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable multiple barcodes for a product</Col>
                  <Col>
                    <Form.Item
                      name="enable_product_multi_barcode"
                      valuePropName="checked"
                      initialValue={companySettings.enable_product_multi_barcode}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable search by name by default for all applicable searches</Col>
                  <Col>
                    <Form.Item
                      name="def_search_by_name"
                      valuePropName="checked"
                      initialValue={companySettings.def_search_by_name}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable ammending the same products together in sales transaction</Col>
                  <Col>
                    <Form.Item
                      name="amend_same_prod"
                      valuePropName="checked"
                      initialValue={companySettings.amend_same_prod}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable ammending the same products together in load transaction</Col>
                  <Col>
                    <Form.Item
                      name="amend_same_prod_in_load"
                      valuePropName="checked"
                      initialValue={companySettings.amend_same_prod_in_load}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable review screen in cash sales transaction</Col>
                  <Col>
                    <Form.Item
                      name="rev_cash_inv"
                      valuePropName="checked"
                      initialValue={companySettings.rev_cash_inv}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable header in transaction prints</Col>
                  <Col>
                    <Form.Item
                      name="print_header"
                      valuePropName="checked"
                      initialValue={companySettings.print_header}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>
                    Enable multiple sale type in a single sales transaction (gr, foc, sale,
                    damage,..)
                  </Col>
                  <Col>
                    <Form.Item
                      name="multi_sales_type_in_single"
                      valuePropName="checked"
                      initialValue={companySettings.multi_sales_type_in_single}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable applying discount in sales return (GR)</Col>
                  <Col>
                    <Form.Item
                      name="enable_discount_in_gr"
                      valuePropName="checked"
                      initialValue={companySettings.enable_discount_in_gr}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable showing suggested quantity for load to van</Col>
                  <Col>
                    <Form.Item
                      name="show_suggested_quantity_in_load"
                      valuePropName="checked"
                      initialValue={companySettings.show_suggested_quantity_in_load}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row
                  gutter={16}
                  justify={'space-between'}
                  align={'middle'}
                  style={{backgroundColor: 'white'}}
                >
                  <Col>Enable remarks in sales transaction</Col>
                  <Col>
                    <Form.Item
                      name="enable_sales_remarks"
                      valuePropName="checked"
                      initialValue={companySettings.enable_sales_remarks}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <Typography.Title
                level={5}
                style={{
                  marginBottom: '12px',
                  marginTop: '24px',
                }}
              >
                Transaction modules
              </Typography.Title>

              <div
                style={{
                  backgroundColor: 'white',
                  padding: '12px',
                  marginBottom: '24px',
                }}
              >
                <Row gutter={16} justify={'space-between'} align={'middle'}>
                  <Col>Sale</Col>
                  <Col>
                    <Form.Item
                      name="trans_mod_sale"
                      valuePropName="checked"
                      initialValue={companySettings.trans_mods?.charAt(0) === '1'}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row gutter={16} justify={'space-between'} align={'middle'}>
                  <Col>Collection</Col>
                  <Col>
                    <Form.Item
                      name="trans_mod_collection"
                      valuePropName="checked"
                      initialValue={companySettings.trans_mods?.charAt(1) === '1'}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row gutter={16} justify={'space-between'} align={'middle'}>
                  <Col>Settlement</Col>
                  <Col>
                    <Form.Item
                      name="trans_mod_settlement"
                      valuePropName="checked"
                      initialValue={companySettings.trans_mods?.charAt(2) === '1'}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row gutter={16} justify={'space-between'} align={'middle'}>
                  <Col>Load</Col>
                  <Col>
                    <Form.Item
                      name="trans_mod_load"
                      valuePropName="checked"
                      initialValue={companySettings.trans_mods?.charAt(3) === '1'}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row gutter={16} justify={'space-between'} align={'middle'}>
                  <Col>Unload</Col>
                  <Col>
                    <Form.Item
                      name="trans_mod_unload"
                      valuePropName="checked"
                      initialValue={companySettings.trans_mods?.charAt(4) === '1'}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{opacity: '.2'}} />
                <Row gutter={16} justify={'space-between'} align={'middle'}>
                  <Col>Advance Collection</Col>
                  <Col>
                    <Form.Item
                      name="trans_mod_advance_collection"
                      valuePropName="checked"
                      initialValue={companySettings.trans_mods?.charAt(5) === '1'}
                      style={{marginBottom: '0'}}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default CompanySettings;
