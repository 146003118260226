import React from 'react';
import AdvanceCollections from '../../../components/Transactions/AdvanceCollections/AdvanceCollectionsTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
  return (
    <ContentView>
      <AdvanceCollections />
    </ContentView>
  );
};
export default index;
