import React, {useState, useEffect} from 'react';
import {Form, Input, Select, Button, Drawer, message} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';

const RegionForm = ({handleDrawerClose, isDrawerVisible, getRegionList, region}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [countryList, setCountryList] = useState([]);

  function getCountryList() {
    getApiWithPublicAuthToken('/api/v1/public/countryList').then(res => {
      if (res.data && res.data.length > 0) {
        setCountryList(res.data);
      }
    });
  }

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [region]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});
    if (region.id) {
      updateApiWithAuthToken(`/api/v1/admin/regions/${region.id}`, values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getRegionList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({
                  content: each.msg,
                  key: 'form',
                });
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    } else {
      postApiWithAuthToken('/api/v1/admin/regions', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getRegionList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({
                  content: each.msg,
                  key: 'form',
                });
                return null;
              });
            } else {
              message.error({
                content: res.errors,
                key: 'form',
              });
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={region.id ? 'Edit region' : 'Add region'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <Form.Item
          name="name"
          label="Name"
          initialValue={region.name}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="ref_code" label="Ref code" initialValue={region.ref_code}>
          <Input />
        </Form.Item>
        <Form.Item name="prefix" label="Prefix" initialValue={region.prefix}>
          <Input />
        </Form.Item>
        <Form.Item
          name="country"
          label="Country"
          initialValue={
            countryList.find(country => country.name === region.country)
              ? countryList.find(country => country.name === region.country).id
              : null
          }
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select
            showSearch
            placeholder="Select a country"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countryList.length &&
              countryList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default RegionForm;
