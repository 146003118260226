import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import setAuthToken from '../utils/setAuthToken';
const configForAuth = {
    headers: {
        'Content-Type': 'application/json',
    },
};

export const getApiWithPublicAuthToken = async (url) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-public-token': process.env.REACT_APP_PUBLIC_AUTH_TOKEN,
        },
    };
    try {
        const res = await trackPromise(
            axios.get(process.env.REACT_APP_BASE_URL + url, config)
        );
        return res.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return error;
        }
    }
};


export const getApiWithAuthToken = async (url) => {
    //console.log(process.env.REACT_APP_BASE_URL);
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await trackPromise(
            axios.get(process.env.REACT_APP_BASE_URL + url)
        );
        return res.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return error;
        }
    }
};
export const postApiWithAuthToken = async (url, formData) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await trackPromise(
            axios.post(
                process.env.REACT_APP_BASE_URL + url,
                formData,
                configForAuth
            )
        );
        return res.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return error;
        }
    }
};
export const deleteApiWithAuthToken = async (url, formData) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await trackPromise(
            axios.delete(
                process.env.REACT_APP_BASE_URL + url,
                formData,
                configForAuth
            )
        );
        return res.data;
    } catch (error) {
        console.log(error.response);
        if (error.response) {
            return error.response.data;
        } else {
            return error;
        }
    }
};
export const updateApiWithAuthToken = async (url, formData) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await trackPromise(
            axios.put(
                process.env.REACT_APP_BASE_URL + url,
                formData,
                configForAuth
            )
        );
        return res.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return error;
        }
    }
};


export const updateWithFileApiWithAuthToken = async (url, formData) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    const config = {
        headers: {
            'content-type': 'multipart/form-data' 
        },
    };

    try {
        const res = await trackPromise(
            axios.put(
                process.env.REACT_APP_BASE_URL + url,
                formData,
                config
            )
        );
        return res.data;
    } catch (error) {
        if (error.response) {
            return error.response.data;
        } else {
            return error;
        }
    }
};
