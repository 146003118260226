import React from 'react';
import SalesmanLogTable from '../../../components/UserMasters/Salesman/SalesmanLogTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
  return (
    <ContentView>
      <SalesmanLogTable />
    </ContentView>
  );
};
export default index;
