import React, {useState, useEffect} from 'react';
import {Button, Table, Row, Descriptions, Popconfirm, message, Typography} from 'antd';
import {getApiWithAuthToken} from '../../../api';
import ProductPriceForm from './ProductPriceForm';
import {useColumnSearchProps, handleDeleteClick, handleTableChange} from '../../../helpers/table';

const ProductPrice = () => {
  // Table states
  const [loading, setLoading] = useState(true);
  const [productPriceList, setProductPriceList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: 10,
    page: 1,
    code: null,
    ref_code: null,
    product: null,
    field: null,
    order: null,
  });

  const getProductPriceList = (qp = queryParams) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/productPrices?limit=${qp.limit}&page=${qp.page}` +
        (qp.code ? `&code=${qp.code}` : '') +
        (qp.ref_code ? `&ref_code=${qp.ref_code}` : '') +
        (qp.product ? `&product=${qp.product}` : '') +
        (qp.field ? `&field=${qp.field}` : '') +
        (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      if (!res.error && res.data?.product_price.length) {
        setTotalCount(res.data.total_count);
        setProductPriceList(
          res.data.product_price.map(each => ({
            id: each.id,
            key: each.id,
            code: each.code,
            ref_code: each.ref_code,
            product: each.product,
            product_id: each.product_id,
            multipack: each.multipack,
            multipack_id: each.multipack_id,
            cost_price: each.cost_price,
            max_discount: each.max_discount,
            sales: each.sales,
            gr: each.gr,
            expiry: each.expiry,
            damage: each.damage,
            foc: each.foc,
            min_sales: each.min_sales,
            min_gr: each.min_gr,
            min_expiry: each.min_expiry,
            min_damage: each.min_damage,
            min_foc: each.min_foc,
            currency: each.currency,
            currency_id: each.currency_id,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({content: 'Network Error!'});
        }
        setProductPriceList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getProductPriceList(queryParams);
  }, []);

  //Edit funciton
  const [selectedProductPrice, setSelectedProductPrice] = useState({});
  const handleEditClick = record => {
    setSelectedProductPrice(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedProductPrice({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...useColumnSearchProps('code', queryParams, setQueryParams, getProductPriceList),
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: 'Ref. Code',
      dataIndex: 'ref_code',
      key: 'ref_code',
      ...useColumnSearchProps('ref_code', queryParams, setQueryParams, getProductPriceList),
      sorter: (a, b) => a.ref_code - b.ref_code,
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      ...useColumnSearchProps('product', queryParams, setQueryParams, getProductPriceList),
      sorter: (a, b) => a.product.localeCompare(b.product),
    },
    {
      title: 'Multipack',
      dataIndex: 'multipack',
      key: 'multipack',
      sorter: (a, b) => a.multipack.localeCompare(b.multipack),
    },
    {
      title: 'Cost Price',
      dataIndex: 'cost_price',
      key: 'cost_price',
      sorter: (a, b) => a.cost_price.localeCompare(b.cost_price),
    },
    {
      title: 'Sales Price',
      dataIndex: 'sales',
      key: 'sales',
      sorter: (a, b) => a.sales.localeCompare(b.sales),
    },
    {
      title: 'Good Return',
      dataIndex: 'gr',
      key: 'gr',
      sorter: (a, b) => a.gr.localeCompare(b.gr),
    },
    {
      title: 'Expiry',
      dataIndex: 'expiry',
      key: 'expiry',
      sorter: (a, b) => a.expiry.localeCompare(b.expiry),
    },
    {
      title: 'Damage',
      dataIndex: 'damage',
      key: 'damage',
      sorter: (a, b) => a.damage.localeCompare(b.damage),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
          <span> </span>
          <Popconfirm
            title="Are you sure to delete this customer price?"
            onConfirm={() => handleDeleteClick(record.id)}
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Product Price
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table
        scroll={{x: 0}}
        dataSource={productPriceList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange(queryParams, setQueryParams, getProductPriceList)}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: queryParams.page,
          pageSize: queryParams.limit,
          size: 'default',
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
        expandable={{
          expandedRowRender: record => (
            <Descriptions bordered style={{backgroundColor: '#fff'}}>
              <Descriptions.Item label="Min. Sales Price">{record.min_sales}</Descriptions.Item>
              <Descriptions.Item label="Min. GR Price">{record.min_gr}</Descriptions.Item>
              <Descriptions.Item label="Min. Expiry Price">{record.min_expiry}</Descriptions.Item>
              <Descriptions.Item label="Min. Damage Price">{record.min_damage}</Descriptions.Item>
              <Descriptions.Item label="Min. FOC Price">{record.min_foc}</Descriptions.Item>
              <Descriptions.Item label="Max Discount">{record.max_discount}</Descriptions.Item>
            </Descriptions>
          ),
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
      />
      <ProductPriceForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getProductPriceList={getProductPriceList}
        productPrice={selectedProductPrice}
      />
    </>
  );
};
export default ProductPrice;
