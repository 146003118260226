import React, {useState, useEffect} from 'react';
import {Button, Table, Row, message, Popconfirm, Typography} from 'antd';
import toTitleCase from 'helpers/toTitleCase';
import {getApiWithAuthToken} from '../../../api';
import CustomerForm from './CustomerForm';
import {useColumnSearchProps, handleDeleteClick, handleTableChange} from '../../../helpers/table';

const Customer = () => {
  // Table states
  const [loading, setLoading] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: 10,
    page: 1,
    name: null,
    code: null,
    ref_code: null,
    field: null,
    order: null,
  });

  const getCustomerList = (qp = queryParams) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/customers?limit=${qp.limit}&page=${qp.page}` +
        (qp.name ? `&name=${qp.name}` : '') +
        (qp.code ? `&code=${qp.code}` : '') +
        (qp.ref_code ? `&ref_code=${qp.ref_code}` : '') +
        (qp.field ? `&field=${qp.field}` : '') +
        (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      if (!res.error && res.data.customers.length) {
        setTotalCount(res.data.total_count);
        setCustomerList(
          res.data.customers.map(each => ({
            id: each.id,
            key: each.id,
            code: each.code,
            ref_code: each.ref_code,
            name: each.name,
            customer_type: each.customer_type,
            description: each.description,
            address: each.address,
            po_box: each.po_box,
            country: each.country,
            region: each.region,
            area: each.area,
            mobile: each.mobile,
            contact_1: each.contact_1,
            contact_2: each.contact_2,
            email: each.email,
            website: each.website,
            trans_type: toTitleCase(each.trans_type),
            trans_type_id: each.trans_type_id,
            multi_trans_type: each.multi_trans_type,
            credit_limit_amt: each.credit_limit_amt,
            credit_limit_days: each.credit_limit_days,
            latitude: each.latitude,
            longitude: each.longitude,
            bank_account_no: each.bank_account_no,
            bank_name: each.bank_name,
            bank_payee_name: each.bank_payee_name,
            vat: each.vat,
            vat_reg_no: each.vat_reg_no,
            def_currency: toTitleCase(each.def_currency),
            def_currency_id: each.def_currency_id,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({
            content: 'Network Error!',
          });
        }
        setCustomerList([]);
      }
      setLoading(false);
    });
  };

  // On load
  useEffect(() => {
    getCustomerList(queryParams);
  }, []);

  // Edit function
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const handleEditClick = record => {
    setSelectedCustomer(record);
    setIsDrawerVisible(true);
  };

  //Drawer function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedCustomer({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      filterSearch: true,
      ...useColumnSearchProps('code', queryParams, setQueryParams, getCustomerList),
      sorter: true,
    },
    {
      title: 'Ref Code',
      dataIndex: 'ref_code',
      key: 'ref_code',
      ...useColumnSearchProps('ref_code', queryParams, setQueryParams, getCustomerList),
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...useColumnSearchProps('name', queryParams, setQueryParams, getCustomerList),
      sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
      title: 'Type',
      dataIndex: 'customer_type',
      key: 'customer_type',
      sorter: (a, b) => (a.customer_type ?? '').localeCompare(b.customer_type ?? ''),
    },
    {
      title: 'Credit Limit Amt.',
      dataIndex: 'credit_limit_amt',
      key: 'credit_limit_amt',
    },
    {
      title: 'Credit Limit Days',
      dataIndex: 'credit_limit_days',
      key: 'credit_limit_days',
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
          <span> </span>
          <Popconfirm
            title="Are you sure to delete this customer?"
            onConfirm={() =>
              handleDeleteClick(record.id, '/api/v1/admin/customers/', () =>
                getCustomerList(queryParams)
              )
            }
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row
        justify="space-between"
        style={{
          margin: '24px 0',
        }}
      >
        <Typography.Title
          level={3}
          style={{
            marginBottom: '0',
          }}
        >
          Customer
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table
        scroll={{
          x: 0,
        }}
        dataSource={customerList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange(queryParams, setQueryParams, getCustomerList)}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: queryParams.page,
          pageSize: queryParams.limit,
          size: 'default',
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} items`,
        }}
      />
      <CustomerForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getCustomerList={getCustomerList}
        customer={selectedCustomer}
      />
    </>
  );
};

export default Customer;
