import React, {useState, useEffect} from 'react';
import {Form, Select, Button, Drawer, message, Checkbox, Row, Col, TimePicker} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';
import moment from 'moment';

const RouteJourneyPlanForm = ({
  handleDrawerClose,
  isDrawerVisible,
  getRouteJourneyPlanList,
  routeJourneyPlan,
}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [routeList, setRouteList] = useState([]);
  const [customerList, setCustomerList] = useState([]);

  function getRouteList() {
    getApiWithPublicAuthToken('/api/v1/admin/routes').then(res => {
      if (res.data && res.data.length > 0) {
        setRouteList(res.data);
      }
    });
  }
  function getCustomerList() {
    getApiWithPublicAuthToken('/api/v1/admin/customers').then(res => {
      if (res.data && res.data.length > 0) {
        setCustomerList(res.data);
      }
    });
  }

  useEffect(() => {
    getRouteList();
    getCustomerList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [routeJourneyPlan]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});
    if (routeJourneyPlan.id) {
      updateApiWithAuthToken(`/api/v1/admin/routeJourneyPlans/${routeJourneyPlan.id}`, values).then(
        res => {
          if (!res.error) {
            setTimeout(() => {
              message.success({content: res.msg, key: 'form'});
            }, 500);
            handleDrawerClose();
            getRouteJourneyPlanList();
          } else {
            setTimeout(() => {
              if (res.errors.length) {
                res.errors.map(each => {
                  message.error({content: each.msg, key: 'form'});
                  return null;
                });
              } else {
                message.error({content: res.errors, key: 'form'});
              }
            }, 500);
          }
        }
      );
    } else {
      postApiWithAuthToken('/api/v1/admin/routeJourneyPlans', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getRouteJourneyPlanList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={routeJourneyPlan.id ? 'Edit route' : 'Add route'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form layout="vertical" hideRequiredMark form={form} onFinish={onFinish}>
        <Form.Item
          name="route_id"
          label="Route"
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select showSearch placeholder="Select a route" optionFilterProp="children">
            {routeList.length &&
              routeList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item style={{marginBottom: '4px'}} name="day_1" label="Day 1">
          <Select
            showSearch
            placeholder="Select a customer"
            optionFilterProp="children"
            mode="multiple"
          >
            {customerList.length &&
              customerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Button
          type="button"
          style={{marginBottom: '24px'}}
          onClick={() => {
            form.setFieldsValue({
              day_2: form.getFieldValue('day_1'),
              day_3: form.getFieldValue('day_1'),
              day_4: form.getFieldValue('day_1'),
              day_5: form.getFieldValue('day_1'),
              day_6: form.getFieldValue('day_1'),
              day_7: form.getFieldValue('day_1'),
            });
          }}
        >
          Apply to all days
        </Button>
        <Form.Item style={{marginBottom: '4px'}} name="day_2" label="Day 2">
          <Select
            showSearch
            placeholder="Select a customer"
            optionFilterProp="children"
            mode="multiple"
          >
            {customerList.length &&
              customerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Button
          type="button"
          style={{marginBottom: '24px'}}
          onClick={() => {
            form.setFieldsValue({
              day_1: form.getFieldValue('day_2'),
              day_3: form.getFieldValue('day_2'),
              day_4: form.getFieldValue('day_2'),
              day_5: form.getFieldValue('day_2'),
              day_6: form.getFieldValue('day_2'),
              day_7: form.getFieldValue('day_2'),
            });
          }}
        >
          Apply to all days
        </Button>
        <Form.Item style={{marginBottom: '4px'}} name="day_3" label="Day 3">
          <Select
            showSearch
            placeholder="Select a customer"
            optionFilterProp="children"
            mode="multiple"
          >
            {customerList.length &&
              customerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Button
          type="button"
          style={{marginBottom: '24px'}}
          onClick={() => {
            form.setFieldsValue({
              day_1: form.getFieldValue('day_3'),
              day_2: form.getFieldValue('day_3'),
              day_4: form.getFieldValue('day_3'),
              day_5: form.getFieldValue('day_3'),
              day_6: form.getFieldValue('day_3'),
              day_7: form.getFieldValue('day_3'),
            });
          }}
        >
          Apply to all days
        </Button>
        <Form.Item style={{marginBottom: '4px'}} name="day_4" label="Day 4">
          <Select
            showSearch
            placeholder="Select a customer"
            optionFilterProp="children"
            mode="multiple"
          >
            {customerList.length &&
              customerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Button
          type="button"
          style={{marginBottom: '24px'}}
          onClick={() => {
            form.setFieldsValue({
              day_1: form.getFieldValue('day_4'),
              day_2: form.getFieldValue('day_4'),
              day_3: form.getFieldValue('day_4'),
              day_5: form.getFieldValue('day_4'),
              day_6: form.getFieldValue('day_4'),
              day_7: form.getFieldValue('day_4'),
            });
          }}
        >
          Apply to all days
        </Button>
        <Form.Item style={{marginBottom: '4px'}} name="day_5" label="Day 5">
          <Select
            showSearch
            placeholder="Select a customer"
            optionFilterProp="children"
            mode="multiple"
          >
            {customerList.length &&
              customerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Button
          type="button"
          style={{marginBottom: '24px'}}
          onClick={() => {
            form.setFieldsValue({
              day_1: form.getFieldValue('day_5'),
              day_2: form.getFieldValue('day_5'),
              day_3: form.getFieldValue('day_5'),
              day_4: form.getFieldValue('day_5'),
              day_6: form.getFieldValue('day_5'),
              day_7: form.getFieldValue('day_5'),
            });
          }}
        >
          Apply to all days
        </Button>
        <Form.Item style={{marginBottom: '4px'}} name="day_6" label="Day 6">
          <Select
            showSearch
            placeholder="Select a customer"
            optionFilterProp="children"
            mode="multiple"
          >
            {customerList.length &&
              customerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Button
          type="button"
          style={{marginBottom: '24px'}}
          onClick={() => {
            form.setFieldsValue({
              day_1: form.getFieldValue('day_6'),
              day_2: form.getFieldValue('day_6'),
              day_3: form.getFieldValue('day_6'),
              day_4: form.getFieldValue('day_6'),
              day_5: form.getFieldValue('day_6'),
              day_7: form.getFieldValue('day_6'),
            });
          }}
        >
          Apply to all days
        </Button>
        <Form.Item style={{marginBottom: '4px'}} name="day_7" label="Day 7">
          <Select
            showSearch
            placeholder="Select a customer"
            optionFilterProp="children"
            mode="multiple"
          >
            {customerList.length &&
              customerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Button
          type="button"
          style={{marginBottom: '24px'}}
          onClick={() => {
            form.setFieldsValue({
              day_1: form.getFieldValue('day_7'),
              day_2: form.getFieldValue('day_7'),
              day_3: form.getFieldValue('day_7'),
              day_4: form.getFieldValue('day_7'),
              day_5: form.getFieldValue('day_7'),
              day_6: form.getFieldValue('day_7'),
            });
          }}
        >
          Apply to all days
        </Button>
        {/* 
                <Row gutter={16}>
                    <Col span={10}>
                        <Form.Item style={{marginBottom:'4px'}} name="day_1" valuePropName="checked" initialValue={routeJourneyPlan.day_1}>
                            <Checkbox>Monday</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="day_1_time"
                            initialValue={moment(routeJourneyPlan.day_1_time).isValid() ? moment.utc(routeJourneyPlan.day_1_time).local() : ''}
                        >
                            <TimePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={10}>
                        <Form.Item style={{marginBottom:'4px'}} name="day_2" valuePropName="checked" initialValue={routeJourneyPlan.day_2}>
                            <Checkbox>Tuesday</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="day_2_time"
                            initialValue={moment(routeJourneyPlan.day_2_time).isValid() ? moment.utc(routeJourneyPlan.day_2_time).local() : ''}
                        >
                            <TimePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={10}>
                        <Form.Item style={{marginBottom:'4px'}} name="day_3" valuePropName="checked" initialValue={routeJourneyPlan.day_3}>
                            <Checkbox>Wednesday</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="day_3_time"
                            initialValue={moment(routeJourneyPlan.day_3_time).isValid() ? moment.utc(routeJourneyPlan.day_3_time).local() : ''}
                        >
                            <TimePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={10}>
                        <Form.Item style={{marginBottom:'4px'}} name="day_4" valuePropName="checked" initialValue={routeJourneyPlan.day_4}>
                            <Checkbox>Thursday</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="day_4_time"
                            initialValue={moment(routeJourneyPlan.day_4_time).isValid() ? moment.utc(routeJourneyPlan.day_4_time).local() : ''}
                        >
                            <TimePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={10}>
                        <Form.Item style={{marginBottom:'4px'}} name="day_5" valuePropName="checked" initialValue={routeJourneyPlan.day_5}>
                            <Checkbox>Friday</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="day_5_time"
                            initialValue={moment(routeJourneyPlan.day_5_time).isValid() ? moment.utc(routeJourneyPlan.day_5_time).local() : ''}
                        >
                            <TimePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={10}>
                        <Form.Item style={{marginBottom:'4px'}} name="day_6" valuePropName="checked" initialValue={routeJourneyPlan.day_6}>
                            <Checkbox>Saturday</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="day_6_time"
                            initialValue={moment(routeJourneyPlan.day_6_time).isValid() ? moment.utc(routeJourneyPlan.day_6_time).local() : ''}
                        >
                            <TimePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={10}>
                        <Form.Item style={{marginBottom:'4px'}} name="day_7" valuePropName="checked" initialValue={routeJourneyPlan.day_7}>
                            <Checkbox>Sunday</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name="day_7_time"
                            initialValue={moment(routeJourneyPlan.day_7_time).isValid() ? moment.utc(routeJourneyPlan.day_7_time).local() : ''}
                        >
                            <TimePicker />
                        </Form.Item>
                    </Col>
                </Row>
           */}
      </Form>
    </Drawer>
  );
};
export default RouteJourneyPlanForm;
