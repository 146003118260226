import React, {useState, useEffect} from 'react';
import {Form, Input, Select, Button, Drawer, message, InputNumber, Space} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

import {
  postApiWithAuthToken,
  updateApiWithAuthToken,
  getApiWithPublicAuthToken,
} from '../../../api';

const ProductPriceForm = ({
  handleDrawerClose,
  getProductPriceList,
  isDrawerVisible,
  productPrice,
}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [productList, setProductList] = useState([]);
  const [multipackList, setMultipackList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  function getProductList() {
    getApiWithPublicAuthToken('/api/v1/admin/products').then(res => {
      if (res.data && res.data.length > 0) {
        setProductList(res.data);
      }
    });
  }
  function getMultipackList() {
    getApiWithPublicAuthToken('/api/v1/admin/productMultipacks').then(res => {
      if (res.data && res.data.length > 0) {
        setMultipackList(res.data);
      }
    });
  }
  function getCurrencyList() {
    getApiWithPublicAuthToken('/api/v1/public/currencyList').then(res => {
      if (res.data && res.data.length > 0) {
        setCurrencyList(res.data);
      }
    });
  }

  useEffect(() => {
    getProductList();
    getMultipackList();
    getCurrencyList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [productPrice]);

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});
    if (productPrice.id) {
      updateApiWithAuthToken(`/api/v1/admin/productPrice/${productPrice.id}`, values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getProductPriceList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    } else {
      postApiWithAuthToken('/api/v1/admin/productPrice', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getProductPriceList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={productPrice.id ? 'Edit Product Price Details' : 'Add New Product Price'}
      width={540}
      onClose={handleDrawerClose}
      bodyStyle={{paddingBottom: 80}}
      visible={isDrawerVisible}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        initialValues={{remember: true}}
        onFinish={onFinish}
      >
        <Form.Item
          name="product"
          initialValue={productPrice ? productPrice.product_id : null}
          label="Product"
        >
          <Select showSearch placeholder="Select a product" optionFilterProp="children">
            {productList.length &&
              productList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="multipack"
          initialValue={productPrice ? productPrice.multipack_id : null}
          label="Multipack"
        >
          <Select showSearch placeholder="Select a multipack" optionFilterProp="children">
            {multipackList.length &&
              multipackList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={'cost_price'}
          label="Cost Price"
          initialValue={productPrice.cost_price}
          rules={[{required: true}]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          name={'sales'}
          label="Sales Price"
          initialValue={productPrice.sales}
          rules={[{required: true}]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          name={'min_sales'}
          label="Min. Sales Price"
          initialValue={productPrice.min_sales}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item name={'gr'} initialValue={productPrice.gr} label="Good Return Price">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item name={'expiry'} initialValue={productPrice.expiry} label="Expiry Price">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item name={'damage'} initialValue={productPrice.damage} label="Damage Price">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item name={'foc'} initialValue={productPrice.foc} label="FOC Price">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          name={'Currency'}
          initialValue={productPrice ? productPrice.currency_id : null}
          label="Currency"
        >
          <Select showSearch placeholder="Select a currency" optionFilterProp="children">
            {currencyList.length &&
              currencyList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name.charAt(0).toUpperCase() + item.name.substr(1).toLowerCase()}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default ProductPriceForm;
